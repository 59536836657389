const IconSpecWeight: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.9999 7C12.2832 7 12.5207 6.90417 12.7124 6.7125C12.904 6.52083 12.9999 6.28333 12.9999 6C12.9999 5.71667 12.904 5.47917 12.7124 5.2875C12.5207 5.09583 12.2832 5 11.9999 5C11.7165 5 11.479 5.09583 11.2874 5.2875C11.0957 5.47917 10.9999 5.71667 10.9999 6C10.9999 6.28333 11.0957 6.52083 11.2874 6.7125C11.479 6.90417 11.7165 7 11.9999 7ZM14.8249 7H16.5749C17.0749 7 17.5082 7.16667 17.8749 7.5C18.2415 7.83333 18.4665 8.24167 18.5499 8.725L19.9749 18.725C20.0582 19.325 19.904 19.8542 19.5124 20.3125C19.1207 20.7708 18.6165 21 17.9999 21H5.99986C5.38319 21 4.87903 20.7708 4.48736 20.3125C4.09569 19.8542 3.94153 19.325 4.02486 18.725L5.44986 8.725C5.53319 8.24167 5.75819 7.83333 6.12486 7.5C6.49153 7.16667 6.92486 7 7.42486 7H9.17486C9.12486 6.83333 9.08319 6.67083 9.04986 6.5125C9.01653 6.35417 8.99986 6.18333 8.99986 6C8.99986 5.16667 9.29153 4.45833 9.87486 3.875C10.4582 3.29167 11.1665 3 11.9999 3C12.8332 3 13.5415 3.29167 14.1249 3.875C14.7082 4.45833 14.9999 5.16667 14.9999 6C14.9999 6.18333 14.9832 6.35417 14.9499 6.5125C14.9165 6.67083 14.8749 6.83333 14.8249 7Z" />
  </svg>
);
IconSpecWeight.displayName = 'IconSpecWeight';
export default IconSpecWeight;
