import type { IMojitoSpecial } from '@/components/sections/mojito/special';
import type { IMY24HeroBanner } from '@/components/sections/my24/home/hero-banner';
import type { IMY24HighlightCarousel } from '@/components/sections/my24/home/highlight-carousel';
import { IMY24LineupCarousel } from '@/components/sections/my24/home/lineup-carousel';
import type { ISplashPage } from '@/components/sections/splash-page';
import { HOME_LINEUP_DATA } from '@/contents/lcv/product/mojito/models';
import { getCta } from '@/contents/lcv/product/my24/cta';

export const META_TAG_DATA = {
  title: 'อีซูซุประเทศไทย Isuzu Thailand Official',
  description:
    'ดูข้อมูลรถอีซูซุ D-Max และ MU-X คำนวณค่างวด เปรียบเทียบรุ่นรถ ขอสินเชื่อ ดูข้อเสนอพิเศษต่างๆ พร้อมทั้งดาวน์โหลดโบรชัวร์ และค้นหาศูนย์บริการใกล้บ้านท่าน',
  img: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ebe5359f8c9db3/62fb311dea0d864c464abcaf/OG_ISUZU.jpg',
  },
};

export const HIGHLIGHT_CAROUSEL_DATA: IMY24HighlightCarousel = {
  active: true,
  customField: 'mojito',
  headline: {
    decorate: false,
    title: '<div class="text-lime">THE PEAK OF</div><span>RS DESIGN</span>',
    description:
      'การออกแบบครั้งใหม่! ที่พลิกโฉม MU-X <span>ให้โดดเด่น</span>และสปอร์ตผ่าน<span>เส้นสาย</span>ที่มีความ DYNAMIC <span>รอบคัน</span>มาพร้อมสีสันอันเป็นเอกลักษณ์เฉพาะ ทําให้ทุก<span>จังหวะพีค</span>... ชัดเจนสูงสุด',
    buttons: [],
  },
  items: [
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt268e2f7bd89fcb86/6665618f3dcdf676032c9cf6/rs-exterior-highlight-1-grille.jpg',
        alt: 'MU-X กระจังหน้าใหม่ พร้อมด้วยสัญลักษณ์ RS',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5aef24c0771b3d07/6665618fa05faea6cac2eb8d/rs-exterior-highlight-1-grille-desktop.jpg',
        alt: 'MU-X กระจังหน้าใหม่ พร้อมด้วยสัญลักษณ์ RS',
        width: 600,
        height: 338,
      },
      title: 'BLACK DIAMOND GRILLE',
      description:
        '<p>ทรงพลังด้วยกระจังหน้าใหม่! BLACK DIAMOND GRILLE พร้อมสะท้อนความ<span>พีค</span>ด้วย<span>สัญลักษณ์ RS</span> และวัสดุ <span>Black Chrome</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4d5a77bc57cbcfbc/6665618f3a1c5f1ff9b80c35/rs-exterior-highlight-2-dynamic-blade.jpg',
        alt: 'MU-X Exterior ไฟหน้าใหม่ DYNAMIC BLADE',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt82c91c885f86381c/6665618f1946587db74c7bb8/rs-exterior-highlight-2-dynamic-blade-desktop.jpg',
        alt: 'MU-X Exterior ไฟหน้าใหม่ DYNAMIC BLADE',
        width: 600,
        height: 338,
      },
      title: 'ไฟหน้าดีไซน์ <span>DYNAMIC BLADE</span>',
      description: '<p>โฉบเฉี่ยวด้วยไฟหน้าใหม่! <span>DYNAMIC BLADE</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfcbd8ba2741df2b1/6665618fc97e381c82f15f8d/rs-exterior-highlight-3-embrace-line.jpg',
        alt: 'MU-X Exterior ไฟท้าย EMBRACE LINE',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1bee52cd38cdbae3/6665618ffe5232e481287911/rs-exterior-highlight-3-embrace-line-desktop.jpg',
        alt: 'MU-X Exterior ไฟท้าย EMBRACE LINE',
        width: 600,
        height: 338,
      },
      title: 'ไฟท้ายดีไซน์ใหม่!',
      description:
        '<p>ชุดไฟท้ายดีไซน์สปอร์ตด้วยเส้น <span>EMBRACE LINE</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt80c0278b764c7669/665e7a8738df322870618b7f/rs-exterior-highlight-4-roof.jpg',
        alt: 'MU-X Exterior สีใหม่ EIGER GRAY OPAQUE และหลังคาดำ GLOSS BLACK ROOF',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt47afb51f534a9796/665e7a87a3c3fee7e64d1dca/rs-exterior-highlight-4-roof-desktop.jpg',
        alt: 'MU-X Exterior สีใหม่ EIGER GRAY OPAQUE และหลังคาดำ GLOSS BLACK ROOF',
        width: 600,
        height: 338,
      },
      title: 'GLOSS BLACK ROOF',
      description:
        '<p>ดีไซน์ใหม่! เท่เร้าใจอารมณ์สปอร์ต <span>ด้วยหลังคาดำ</span> <span>GLOSS BLACK ROOF</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5b1ea76b0e71b919/665e7a87d52e440c01ac70a2/rs-exterior-highlight-5-alloy-wheel.jpg',
        alt: 'MU-X Exterior ล้ออัลลอยใหม่ RS DESIGN ขนาด 20 นิ้ว',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcb8e1b1c5bb3a62d/665e7a87742a0c628d7c65ca/rs-exterior-highlight-5-alloy-wheel-desktop.jpg',
        alt: 'MU-X Exterior ล้ออัลลอยใหม่ RS DESIGN ขนาด 20 นิ้ว',
        width: 600,
        height: 338,
      },
      title: 'ล้ออัลลอย <span>RS DESIGN</span>',
      description:
        '<p>ล้ออัลลอยใหม่! RS DESIGN ขนาด 20 นิ้ว <span>เพิ่มความ</span>สปอร์ตด้วย <span>FENDER GARNISH สีดำ</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf50b28b9fc380a72/665e7a88dbfae913b60f993c/rs-interior-highlight-6-interior.jpg',
        alt: 'MU-X Interior ภายในห้องโดยสารให้พีคกว่าเดิมด้วยโทนสีดำใหม่',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt81e53cd3fa325652/665e7a8870d09b931dd62db7/rs-interior-highlight-6-interior-desktop.jpg',
        alt: 'MU-X Interior ภายในห้องโดยสารให้พีคกว่าเดิมด้วยโทนสีดำใหม่',
        width: 600,
        height: 338,
      },
      title: 'ภายในใหม่! RS Style',
      description:
        '<p>พีคกว่าเดิมด้วยห้องโดยสารโทนสีดำใหม่! <span>พร้อมตกแต่ง</span>ด้วย MATTE SILVER GARNISH</p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9a699e8fb9f4232c/665e8c1d85966b28717175d3/rs-interior-highlight-7-seat.jpg',
        alt: 'MU-X Interior เบาะนั่งดีไซน์สปอร์ตใหม่',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1df3fe7a625918fc/665e7a8885966bd2d6717577/rs-interior-highlight-7-seat-desktop.jpg',
        alt: 'MU-X Interior เบาะนั่งดีไซน์สปอร์ตใหม่',
        width: 600,
        height: 338,
      },
      title: 'เบาะนั่งดีไซน์<span>สปอร์ตใหม่!</span>',
      description:
        '<p>เพอร์เฟกต์ทุกองศา นั่งสบายโอบรับสรีระ <span>โดดเด่น</span>ด้วยการเดินด้ายสี <span>LIME GREEN</span> และ<span>สัญลักษณ์ RS</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf412208d4347e50d/665e7a8702ed7f6da6e82da7/rs-interior-highlight-8-ambient-light.jpg',
        alt: 'MU-X Interior RED AMBIENT LIGHT',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1f75e8b37f2a94c3/665e7a87ba8809cb9253aeb6/rs-interior-highlight-8-ambient-light-desktop.jpg',
        alt: 'MU-X Interior RED AMBIENT LIGHT',
        width: 600,
        height: 338,
      },
      title: 'RED AMBIENT LIGHT',
      description:
        '<p>เร้าใจทุกช่วงเวลา ด้วยบรรยากาศภายใน <span>RED AMBIENT LIGHT</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
  ],
};

export const SPLASH_DATA: ISplashPage = {
  contentGradient: false,
  headline: {
    preTitle: `NEW! MU-X THE NEXT PEAK`,
    title: '<strong>จุดสูงสุดใหม่... กับชีวิต</strong>ที่เหนือกว่า',
    buttons: [
      {
        label: 'เข้าสู่เว็บไซต์',
        color: 'button-white',
        border: true,
      },
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-mu-x',
        color: 'button-gray',
      },
    ],
  },
  bg: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1d10594b096c9d54/6666a6717f40f40535283e80/splash-mobile-bg.jpg',
    alt: 'Isuzu Mu-X',
    width: 600,
    height: 800,
  },
  bgDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf7415b1bde7a152c/665fe64cca40f90e7d64b85d/splash-desktop-bg.jpg',
    alt: 'Isuzu Mu-X',
    width: 600,
    height: 338,
  },
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb0fdd3e422c531a5/665fdfa4a3c3fe52694d2878/logo-isuzu-mu-x.png',
    alt: 'Isuzu Mu-X',
    width: 208,
    height: 91,
  },
  imageDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb0fdd3e422c531a5/665fdfa4a3c3fe52694d2878/logo-isuzu-mu-x.png',
    alt: 'Isuzu Mu-X',
    width: 297,
    height: 65,
  },
};

export const SPLASH_CAMPAIGN_DATA: ISplashPage = {
  contentGradient: false,
  headline: {
    preTitle: `BE YOUR NEXT PEAK`,
    title: 'เป็นตัวคุณในลุคสุดพีค',
    buttons: [
      {
        label: 'เข้าสู่เว็บไซต์',
        color: 'button-white',
        border: true,
      },
      {
        label: 'เริ่มเล่น',
        url: '/campaigns/mu-x-be-your-next-peak?ref=splash',
        color: 'button-red',
        target: '_blank',
      },
    ],
  },
  bg: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt206ae70074bf97cf/66bc8de22f80d2a457f33ad1/splash-mobile.jpg',
    alt: 'Mu-X Be Your Next Peak',
    width: 1920,
    height: 2560,
  },
  bgDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3e927cacbb51b781/66bc8de2473507844d09248b/splash-desktop.jpg',
    alt: 'Mu-X Be Your Next Peak',
    width: 2560,
    height: 1440,
  },
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt469d091a8cdfd2bd/66c4513694d5b333c2e3a82b/logo-mu-x-the-next-peak.png',
    alt: 'Isuzu Mu-X',
    width: 192,
    height: 48,
  },
  imageDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt469d091a8cdfd2bd/66c4513694d5b333c2e3a82b/logo-mu-x-the-next-peak.png',
    alt: 'Isuzu Mu-X',
    width: 320,
    height: 80,
  },
};

export const HERO_BANNER_DATA: IMY24HeroBanner = {
  headline: {
    title: 'อีซูซุประเทศไทย Isuzu Thailand Official',
  },
  items: [
    {
      title:
        'NEW! MU-X THE NEXT PEAK <span>จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf1f8418189b67ee7/66615477d52e448562ac8971/thumbnail-mobile.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9308ff144bd6d094/6661547738df32438f61a48e/thumbnail-desktop.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt52a7c9106e184d64/66629cebabe439543ad162a0/mu-x-video-2024.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt85bf616b9e9d338f/66629ccc67d4ad538458c3ff/mu-x-video-desktop-2024.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt582781a2b1def043/665fdfa4ce5a1c60960c9766/logo-rs.png',
        alt: 'MU-X The next peak',
        width: 300,
        height: 72,
      },
      logoDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltba92af469e5556e4/665ec223ce5a1c82720c8d14/logo-isuzu-mu-x.png',
        alt: 'MU-X The next peak',
        width: 640,
        height: 281,
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: 'reybjy5qUn4',
        icon: 'play-round',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด MU-X',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title: 'อีซูซุ เอ็กซ์-ซีรี่ส์ <span>แรง...ทะลุเวิร์ส</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta1e226dd3314117a/65a9b35acdbb968a6bc504aa/x-series-cover.jpg',
        alt: 'Isuzu X-series Gotta Xross The Line!',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt53843e5680963db7/65a9b35a14eace44efa5b1aa/x-series-cover-desktop.jpg',
        alt: 'Isuzu X-series Gotta Xross The Line!',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc6d8f4a5d1a7c758/65a9b35c7fc0005be4223f29/x-series-video.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt05219b63a330f641/65a9b35c573161bd53cc7a1f/x-series-video-desktop.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0ce9a8fb9081feb0/65b64afe08561627ea5e07b4/logo-isuzu-x-series.png',
        alt: 'Isuzu X-series Gotta Xross The Line!',
        width: 300,
        height: 72,
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: '71hG0N3FGL4',
        icon: 'play-round',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด X-SERIES',
          url: '/isuzu-x-series',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title: 'ใหม่! อีซูซุ วี-ครอส 4x4 <span>เหนือลิมิต...พิชิตโลก</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea1218288e823af3/66b584149519f07a45729977/video-v-cross-poster-mobile.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt91fa3eaafe6127a1/651d14803091588fb9f08701/video-v-cross-poster.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea1218288e823af3/651d2b7f2b65937d39c575b6/video-v-cross-mobile.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte4dbac641c915dd1/651d27fb1d2dc3b0c7fb9348/video-v-cross-desktop.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4721b6e8faee609d/651528c9b16d6d5596554560/logo-new-isuzu-v-cross-4x4-white.png',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 300,
        height: 72,
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: '_kfwyc4lRbg',
        icon: 'play-round',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด D-MAX',
          url: '/isuzu-v-cross',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'ใหม่! อีซูซุ ดีแมคซ์ ปิกอัพ 4 ประตู <span>เหนือลิมิต...พิชิตโลก</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta6b975ac00d6b6dc/66b58600c5b50d0380c284e2/video-4-door-poster-mobile.jpg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2498511e88c6fe4e/651d1be26c9e3605dac0abfa/video-4-door-poster.jpg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5d841ad88f0bbaab/651d2f36964d9bc82ec31a38/video-4-door-mobile.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt03da28596ee50f25/651d2f15d09c523e5a779231/video-4-door-desktop.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt90599ea4859b6c8c/64fd7594b169adb40804b401/logo-new-isuzu-hi-lander-white.svg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 300,
        height: 72,
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: 'fYvUDS2q2uA',
        icon: 'play-round',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด D-MAX',
          url: '/isuzu-pick-up-4-door',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'ใหม่! อีซูซุ ดีแมคซ์ ปิกอัพ 2 ประตู <span>เหนือลิมิต...พิชิตโลก</span>',

      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt503478eaa5a523c1/655453e3c787a42a7c2ab4e0/2door_9x16.jpeg',
        alt: 'ใหม่! อีซูซุ ดีแมคซ์ ปิกอัพ 2 ประตู เหนือลิมิต...พิชิตโลก',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5b8ce8f1e7ff5ec3/655453e34e0195129abf1016/2door_16x9.jpeg',
        alt: 'ใหม่! อีซูซุ ดีแมคซ์ ปิกอัพ 2 ประตู เหนือลิมิต...พิชิตโลก',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd5f4a6c5dc940f0b/6552e57a9f2b99a365bc946b/video-2-door-mobile-3mb.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt333a29800186970c/6552e57b4452cc5ddaf94e04/video-2-door-desktop.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt79254971f8718e02/64fd73869da01566bb1ebb9c/logo-new-isuzu-spacecab-white.svg',
        alt: 'NEW! ISUZU D-MAX SPACECAB',
        width: 300,
        height: 72,
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: 'ujuel-5O9yI',
        icon: 'play-round',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด D-MAX',
          url: '/isuzu-pick-up-2-door',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title: 'ใหม่! อีซูซุ ดีแมคซ์ สปาร์ค <span>เหนือลิมิต...พิชิตโลก</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta3546aaf04e26ae9/65094177d251aa7e3718b12a/spark-mobile.jpg',
        alt: 'ใหม่! อีซูซุ ดีแมคซ์ สปาร์ค เหนือลิมิต...พิชิตโลก',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf222eb1d23bd8ba9/650941768a15ce341cd97cdf/spark-desktop.jpg',
        alt: 'ใหม่! อีซูซุ ดีแมคซ์ สปาร์ค เหนือลิมิต...พิชิตโลก',
        width: 600,
        height: 338,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd531cf0bb3b4ed81/64fd71a1bb60f75a2b4803cc/logo-new-isuzu-spark-white.svg',
        alt: 'NEW! ISUZU D-MAX SPARK',
        width: 300,
        height: 72,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด D-MAX',
          url: '/isuzu-spark',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
  ],
};

export const SPECIAL_DATA: IMojitoSpecial = {
  startPolygonNumber: 7,
  slidePolygonSize: {
    portrait: '135vw',
    portraitXs: '75vh',
    landscape: '110vh',
    landscape1200: '125vh',
    polygonHeightScale: 1.15546218487,
    backgroundScale: 1.28,
  },
  slides: [
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt814dcea6510efada/665de3d002ed7f637fe82747/special-section-1.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 1920,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea75361f8ced80d8/665de3d085966b7103716e57/special-section-1-desktop.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 3560,
      },
      startAngle: 65,
      endAngle: 45,
      backgroundColor: 'rgb(88 ,107, 119)',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4487c80c0b76861a/6666bb8ffe5232202c287b98/special-section-2-v2.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 1920,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt54ad87613dcee68b/6666bb8ffe523220e2287b94/special-section-2-desktop-v2.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 3560,
      },
      startAngle: 90,
      endAngle: 75,
      backgroundColor:
        'linear-gradient(98deg, rgb(57, 82, 107) 37%, rgb(108, 103, 90) 53%)',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt092be2dad607ed0e/66694b4a3ab7db146b188afe/special-section-3.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 1920,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea5af028939ed057/66694b4a0c0c3ef5c903605a/special-section-3-desktop.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 3560,
      },
      startAngle: 65,
      endAngle: 45,
      backgroundColor:
        'linear-gradient(321deg, rgb(28 50 65) 42%, rgb(101 183 222) 68%)',
      imageOffset: {
        portrait: {
          x: 0,
          y: 17,
        },
        landscape: {
          x: 0,
          y: 55,
        },
      },
    },
  ],
  lead: {
    url: '/isuzu-mu-x',
    label: 'ดูรายละเอียด MU-X',
    test: {
      dataTest: 'mojito_special_ctaButton',
    },
    tracking: {
      dataTrack: 'lcv-home',
      dataTrackSection: 'mojito-special',
      dataTrackText: 'ดูรายละเอียด MU-X',
      dataTrackUrl: '/isuzu-mu-x',
    },
  },
  title: {
    lg: {
      firstLine: 'MU-X',
      secondLine: 'THE NEXT PEAK',
    },
    maxlg: {
      firstLine: 'MU-X',
      secondLine: 'THE NEXT PEAK',
    },
  },
};

export const LINEUP_CAROUSEL_DATA: IMY24LineupCarousel = {
  active: true,
  headline: {
    title: 'สำรวจรุ่นรถของเรา',
  },
  items: HOME_LINEUP_DATA,
};

export const CTA_DATA = {
  ...getCta('home'),
};
