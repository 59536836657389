import { Box } from '@/components/shared/layout/box';
import { Flex } from '@/components/shared/layout/flex';
import { Grid } from '@/components/shared/layout/grid';
import { styled } from '@/stitches.config';

export const ProductList = styled(Box, {
  position: 'relative',
  '@lg': {
    display: 'flex',
  },
});

export const ProductListContent = styled(Box, {
  '@lg': {
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: '100%',
  },
});

export const ProductListContentItems = styled(Grid, {
  '@xs': {
    gridTemplateColumns: 'repeat(auto-fill, minmax(272px, 1fr))',
  },
  '@xl': {
    gridTemplateColumns: 'repeat(auto-fill, minmax(312px, 1fr))',
  },
});

// Filter Panel
export const ProductListFilterPanel = styled('div', {
  '@xs': {
    width: '100%',
    maxWidth: 374,
  },
  '@lg': {
    zIndex: 1,
  },
  '@maxlg': {
    position: 'fixed',
    left: 0,
    top: 'var(--sticky-top)',
    width: 'calc(100% - 40px)',
    height: 'var(--sticky-viewport-height)',
    zIndex: '$modal',
    pe: 'none',
    transform: 'translateX(-100%)',
    transition: 'transform var(--transition-duration) var(--transition-easing)',
    '&.is-active': {
      pe: 'auto',
      transform: 'translateX(0)',
    },
  },
});

export const ProductListFilterPanelOuter = styled(Flex, {
  '@maxlg': {
    position: 'relative',
    height: '100%',
    zIndex: 1,
  },
});

export const ProductListFilterPanelBody = styled(Box, {
  '@maxlg': {
    flexGrow: 1,
    flexBasis: 0,
    maxHeight: '100%',
    overflow: 'hidden',
  },
});

export const ProductListFilterPanelBodyScroll = styled(Box, {
  '@maxlg': {
    height: '100%',
    overflow: 'hidden auto',
    '-webkit-overflow-scrolling': 'touch',
  },
});

export const ProductListResetFilter = styled('button', {
  all: 'unset',
  cursor: 'pointer',
});

export const ProductListFilterPanelOverlay = styled(Box, {
  transition: 'opacity var(--transition-duration) var(--transition-easing)',
  '&.is-active': {
    pe: 'auto',
    opacity: 1,
  },
  '@lg': {
    display: 'none',
  },
});
