import Head from 'next/head';
import { useRouter } from 'next/navigation';
import React from 'react';

import { clearCvProductList } from '@/components/sections/product-listing';
import { Button } from '@/components/shared/buttons/button';
import { ButtonGroup } from '@/components/shared/buttons/button-group';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { Flex } from '@/components/shared/layout/flex';
import { Image } from '@/components/shared/media/image';
import { clamp } from '@/components/shared/utility/add-ons';
import { removeSpan, youtubeURL } from '@/components/shared/utility/format';
import { keyframes, styled } from '@/stitches.config';
import { IItem, IMy24Theme, IMy24Tracking } from '@/types/shared';

const goToSection = (section: string) => {
  if (
    section &&
    section !== '/' &&
    section !== '#' &&
    section !== '' &&
    section !== undefined
  ) {
    const SECTION =
      document.querySelector(`[data-section="${section}"]`) ||
      document.querySelector(`[data-hash="${section}"]`);
    const SECTION_TOP = SECTION?.getBoundingClientRect().top;
    const SECTION_OFFSET_TOP = clamp(SECTION_TOP + window.scrollY);
    scrollTo({
      top: SECTION_OFFSET_TOP,
      behavior: 'smooth',
    });
  } else {
    scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

const StyledBannerContent = styled(Box, {
  textAlign: 'center',
  transform: 'translateZ(0)',
  pe: 'none',
  backfaceVisibility: 'hidden',
  transformStyle: 'preserve-3d',
  willChange: 'transform',
  '@lg': {
    textAlign: 'left',
  },
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    left: 0,
    pe: 'none',
  },
  '&::before': {
    top: 0,
    height: 200,
    linearGradient: 'rgba(0, 0, 0, 0.25) 0%, rgb(0, 0, 0, 0) 100%',
    '@lg': {
      height: '100%',
      linearGradient: '25deg, rgb(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 50%',
    },
  },
  '&::after': {
    bottom: 0,
    height: 300,
    zIndex: -1,
    linearGradient: 'rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0, 0.25) 100%',
    '@lg': {
      content: 'none',
    },
  },
  '& > *': {
    height: '100%',
  },
});

const BannerContent = styled(Flex, {
  position: 'relative',
  height: '100%',
  paddingTop: '$space-10',
  paddingBottom: 'calc($space-10 + $space-1 + $space-6)',
  transform: 'translateZ(0)',
  '@xs': {
    paddingTop: '$space-10',
    paddingBottom: 'calc($space-10 + $space-1 + $space-8)',
  },
  '@sm': {
    paddingTop: '$space-14',
    paddingBottom: 'calc($space-14 + $space-1 + $space-8)',
  },
  '@md': {
    paddingTop: '$space-16',
    paddingBottom: 'calc($space-16 + $space-1 + $space-8)',
  },
  '@lg': {
    paddingTop: '$space-14',
    paddingBottom: '$space-14',
  },
  variants: {
    isOnlyChild: {
      true: {
        paddingBottom: '$space-10',
        '@xs': {
          paddingBottom: '$space-10',
        },
        '@sm': {
          paddingBottom: '$space-14',
        },
        '@md': {
          paddingBottom: '$space-16',
        },
        '@lg': {
          paddingBottom: '$space-16',
        },
      },
    },
  },
});

const BannerContentLogo = styled(Box, {
  width: 'calc(200px + (300 - 200) * ((100vw - 360px) / (768 - 360)))',
  mx: 'auto',
  '@md': {
    width: 300,
  },
  '@lg': {
    width: 'calc(232px + (300 - 232) * ((100vw - 1024px) / (1440 - 1024)))',
    mx: '$space-0',
  },
  '@2xl': {
    width: 300,
  },
});

const BannerContentLogoDecorate = styled(Box, {
  position: 'absolute',
  left: 'calc(285 / 300 * 100%)',
  bottom: 'calc(16 / 72 * 100%)',
  width: 'calc(96 / 300 * 100%)',
  height: 'auto',
  aspectRatio: '210 / 234',
});

const sprite = keyframes({
  to: { backgroundPosition: '-3150px' },
});

const Butterfly = styled('div', {
  position: 'absolute',
  width: 210,
  aspectRatio: '210 / 234',
  left: '-16%',
  top: '26%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0 0',
  backgroundSize: '3150px 234px',
  animation: `${sprite} 1s steps(15) infinite`,
  transform: 'scale(calc(64/210)) rotate(-28deg)',
  transformOrigin: '0 0',
  '@xs': {
    transform: 'scale(calc(74/210)) rotate(-28deg)',
  },
  '@md': {
    transform: 'scale(calc(96/210)) rotate(-28deg)',
  },
  '@lg': {
    top: '32%',
    transform: 'scale(calc(74/210)) rotate(-28deg)',
  },
  '@xl': {
    left: '-14%',
    top: '38%',
    transform: 'scale(calc(86/210)) rotate(-28deg)',
  },
});

const BannerContentTitle = styled(Text, {
  textShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
  mx: 'auto',
  '@lg': {
    mx: '$space-0',
  },
});

const Video = styled(Box, {
  pe: 'all',
  fontSize: 0,
});

interface IProps extends React.ComponentProps<typeof StyledBannerContent> {
  css?: any;
  className?: string;
  theme?: IMy24Theme;
  item?: IItem;
  dataTest?: string;
  dataTrack?: IMy24Tracking;
  onClick?: () => void;
  isCloseSplash?: boolean;
  itemId?: number;
  isOnlyChild?: boolean;
  containerElement?: HTMLElement;
}

type UrlFunctionMap = {
  [key: string]: (...args: any[]) => any;
};

export const BannerContentWithLogo = ({
  css,
  className,
  item,
  itemId,
  isCloseSplash,
  containerElement,
  onClick,
  dataTrack,
  isOnlyChild,
  theme = 'dark',
  dataTest = 'banner',
}: IProps) => {
  const router = useRouter();

  const SPECIAL_URLS_CV: UrlFunctionMap = {
    '/?section=lcs': () => {
      goToSection('lcs');
    },
    '#specialmodel': () => {
      goToSection('#specialmodel');
    },
    '/product': () => {
      clearCvProductList();
      router.push('/product');
    },
  };

  const SPECIAL_URLS_LCV: UrlFunctionMap = {};

  const handleButtonLinkProps = (url: string) => {
    switch (process.env.NEXT_PUBLIC_WEB) {
      case 'cv':
        return SPECIAL_URLS_CV[url]
          ? { onClick: SPECIAL_URLS_CV[url] }
          : { href: url };
      case 'lcv':
        return SPECIAL_URLS_LCV[url]
          ? { onClick: SPECIAL_URLS_LCV[url] }
          : { href: url };
    }
  };

  return (
    <StyledBannerContent
      css={css}
      className={className}
      position="absolute"
      top="0"
      left="0"
      width="full"
      height="full"
      zIndex="1"
    >
      <Container>
        <BannerContent
          direction="column"
          justifyContent={{ '@initial': 'between', '@lg': 'end' }}
          rowGap={item?.preTitle ? '8' : '6'}
          isOnlyChild={isOnlyChild}
        >
          <Flex
            direction="column"
            rowGap={item?.preTitle ? '4' : { '@initial': '4', '@sm': '6' }}
          >
            {!!item?.logo?.src && (
              <BannerContentLogo position="relative">
                <Image
                  priority={itemId === 0 && isCloseSplash}
                  rootRef={containerElement}
                  rootMargin={isCloseSplash ? '0px 50% 0px 0px' : '-1px'}
                  src={item?.logo?.src}
                  alt={item?.logo?.alt}
                  width={item?.logo?.width}
                  height={item?.logo?.height}
                  layout="responsive"
                  objectFit="contain"
                  objectPosition={{
                    '@initial': 'bottom',
                    '@lg': 'left-bottom',
                  }}
                  sources={[
                    {
                      srcset: item?.logoDesktop?.src || item?.logo?.src,
                      media: { minWidth: 1024 },
                      options: { width: 320 },
                    },
                    {
                      srcset: item?.logo?.src,
                      media: { maxWidth: 1023 },
                      options: { width: 256 },
                    },
                  ]}
                  css={{
                    '@lg':
                      item?.logoDesktop?.width && item?.logoDesktop?.height
                        ? {
                            aspectRatio: `${item?.logoDesktop?.width} / ${item?.logoDesktop?.height}`,
                          }
                        : {},
                  }}
                />
                {!!item?.decorate && (
                  <>
                    <Head>
                      <link
                        key="preload-butterfly"
                        rel="preload"
                        as="image"
                        href={`${item.decorate.src}?format=pjpg&auto=webp&quality=75`}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // eslint-disable-next-line react/no-unknown-property
                        fetchpriority="high"
                      />
                    </Head>
                    <BannerContentLogoDecorate>
                      <Butterfly
                        css={{
                          backgroundImage: `url(${item?.decorate?.src}?format=pjpg&auto=webp&quality=75)`,
                        }}
                      />
                    </BannerContentLogoDecorate>
                  </>
                )}
              </BannerContentLogo>
            )}
            <Flex
              direction="column"
              rowGap={{ '@initial': '2', '@xs': '3' }}
              css={{ transform: 'translateZ(0)' }}
            >
              {!!item?.preTitle && (
                <BannerContentTitle
                  as="h2"
                  size="text-h2"
                  font="bold"
                  variant={
                    theme === 'dark' ? 'text-white' : 'text-gray-darkest'
                  }
                  wrap
                  dangerouslySetInnerHTML={{
                    __html: item?.preTitle,
                  }}
                  data-test={`${dataTest}_pre_title`}
                />
              )}
              {!!item?.title && (
                <BannerContentTitle
                  as="h2"
                  size="text-sub-h2"
                  font="bold"
                  variant={
                    theme === 'dark' ? 'text-white' : 'text-gray-darkest'
                  }
                  wrap
                  dangerouslySetInnerHTML={{
                    __html: item?.title,
                  }}
                  data-test={`${dataTest}_title`}
                  css={{ maxWidth: '$size-96' }}
                />
              )}
            </Flex>
          </Flex>
          <Flex
            direction="column"
            justifyContent="center"
            rowGap="6"
            css={{
              position: 'relative',
              '@lg': !isOnlyChild && {
                paddingBottom: 'calc($space-10 + $space-1)',
              },
            }}
          >
            {!!item?.youtube && (
              <Video
                position={{
                  '@initial': 'relative',
                  '@lg': 'absolute',
                }}
                right={{ '@lg': '0' }}
                bottom={{ '@lg': '0' }}
              >
                <Button
                  label={item?.youtube?.label}
                  border={item?.youtube?.border}
                  icon={item?.youtube?.icon}
                  size={item?.youtube?.size}
                  isTransparent
                  isIconLarge
                  onClick={onClick}
                  tracking={{
                    dataTrack: dataTrack,
                    dataTrackSection: dataTest,
                    dataTrackText: item?.youtube?.label,
                    dataTrackValue: removeSpan(item?.title),
                    dataTrackUrl: youtubeURL(item?.youtube?.url),
                  }}
                  test={{
                    dataTest: `${dataTest}_videoButton`,
                  }}
                />
              </Video>
            )}
            {item?.buttons?.length > 0 && (
              <ButtonGroup justify={{ '@initial': 'center', '@lg': 'start' }}>
                {item?.buttons?.map((button, i) => (
                  <Button
                    key={i}
                    border={button?.border}
                    label={button?.label}
                    icon={button?.icon}
                    variant={button?.color}
                    size={button?.size}
                    isMinWidth
                    {...handleButtonLinkProps(button.url)}
                    target={button.target}
                    tracking={{
                      dataTrack: dataTrack,
                      dataTrackSection: dataTest,
                      dataTrackText: button?.label,
                      dataTrackValue: removeSpan(item?.title),
                      dataTrackUrl: button?.url,
                    }}
                    test={{
                      dataTest: `${dataTest}_ctaButton_${i + 1}`,
                    }}
                    css={{
                      pe: 'all',
                    }}
                  />
                ))}
              </ButtonGroup>
            )}
          </Flex>
        </BannerContent>
      </Container>
    </StyledBannerContent>
  );
};

BannerContentWithLogo.displayName = 'BannerContentWithLogo';
