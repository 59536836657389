import { Icon } from '@/components/shared/element/icons';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Flex } from '@/components/shared/layout/flex';
import { Grid } from '@/components/shared/layout/grid';

const View: React.FC = () => (
  <Flex
    alignItems="center"
    justifyContent="center"
    css={{ flexGrow: 1, flexBasis: 0, maxHeight: '100%' }}
  >
    <Grid
      alignItems="center"
      justifyContent="center"
      rowGap="2"
      css={{
        textAlign: 'center',
        py: '$space-16',
      }}
    >
      <Box>
        <Icon icon="face-sad" size="lg" color="icon-gray-light" />
      </Box>
      <Box>
        <Text as="p" variant="text-gray">
          ไม่พบรุ่นรถบรรทุก กรุณาเลือกตัวกรองใหม่
        </Text>
      </Box>
    </Grid>
  </Flex>
);
View.displayName = 'SectionProductListingNotFound';
export default View;
