import { Container } from 'components/shared/layout/container';
import React, { useCallback, useEffect, useRef } from 'react';

import { NebularBackground } from '@/components/sections/splash-prelaunch/bg';
import { MaxforceLogoWithAnimation } from '@/components/sections/splash-prelaunch/number';
import { Box } from '@/components/shared/layout/box';
import { ImageProps } from '@/components/shared/media/image/Image.props';
import { ContentWithAction } from '@/components/shared/my24/content-with-action';
import {
  clearBodyLockScroll,
  enableBodyLockScroll,
} from '@/components/shared/utility/lock-scroll';
import { styled } from '@/stitches.config';
import { IItem, IMy24Tracking } from '@/types/shared';

const Splash = styled('section', {
  position: 'fixed',
  width: '100%',
  height: 'var(--viewport-height)',
  left: 0,
  top: 0,
  bc: '$black',
  zIndex: '$popup',
  '@supports (height: 100svh)': {
    height: '100svh',
  },
});

const ButtonWrapper = styled(Box, {
  '& .content-with-action': {
    '& .content-buttons': {
      flexWrap: 'wrap',
      '@md': {
        flexDirection: 'row-reverse',
      },
    },
  },
});

export interface SplashPrelaunchProps {
  logo?: ImageProps;
  headline: IItem;
  dataTrack?: IMy24Tracking;
  onClose?: () => void;
}

export const SplashPrelaunch = ({
  logo,
  headline,
  onClose,
  dataTrack = 'lcv-home',
}: SplashPrelaunchProps) => {
  const SPLASH_REF = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const disableScrolling = (e: PointerEvent | TouchEvent) => {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onCloseSplash = useCallback(() => {
    clearBodyLockScroll({ important: true });

    const splashElement = SPLASH_REF.current;
    if (!splashElement) return;

    splashElement.removeEventListener('pointermove', disableScrolling);
    splashElement.removeEventListener('touchmove', disableScrolling);

    if (onClose) {
      onClose();
    }
  }, [disableScrolling, onClose]);

  useEffect(() => {
    const splashElement = SPLASH_REF.current;
    if (!splashElement) return;

    enableBodyLockScroll({ important: true });
    splashElement.addEventListener('pointermove', disableScrolling, {
      passive: false,
    });
    splashElement.addEventListener('touchmove', disableScrolling, {
      passive: false,
    });

    return () => {
      clearBodyLockScroll({ important: true });
      splashElement.removeEventListener('pointermove', disableScrolling);
      splashElement.removeEventListener('touchmove', disableScrolling);
    };
  }, []);

  return (
    <Splash ref={SPLASH_REF} data-test="splash">
      <Box position="absolute" inset="0">
        <NebularBackground />
      </Box>
      <Container height="full">
        <Box
          position="relative"
          width="full"
          height="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {!!logo && <MaxforceLogoWithAnimation logo={logo} />}
          <ButtonWrapper
            position="absolute"
            width="full"
            bottom={{ '@initial': 10, '@md': 20 }}
          >
            <ContentWithAction
              className="content-with-action"
              headline={headline}
              dataTest="splash"
              dataTrack={dataTrack}
              onClick={onCloseSplash}
            />
          </ButtonWrapper>
        </Box>
      </Container>
    </Splash>
  );
};
