import { keyframes, VariantProps } from '@stitches/react';

import { Box } from '@/components/shared/layout/box';
import { Image } from '@/components/shared/media/image';
import { ImageProps } from '@/components/shared/media/image/Image.props';
import { styled } from '@/stitches.config';

export const NUMBER_0 = () => (
  <path
    d="M201.35 17.567 177.243 3.154C175.424 1.714 172.112 0 170.293 0H58.176c-1.819 0-8.253 1.55-10.099 5.303l-37.87 59.334L1.33 78.37c-3.774 5.112 1.493 8.62 3.339 8.62h146.947c1.819 0 9.366-.054 12.814-5.303l1.574-2.556h.19l25.6-40.68c.081-.082.19-.163.271-.245l10.099-15.31c1.303-2.23 1.032-3.861-.76-5.33h-.054ZM92.3 17.322h61.569l-30.377 47.587H61.976L92.3 17.322Z"
    fill="currentColor"
  />
);

export const NUMBER_1 = () => (
  <path
    d="M123.271 0H73.075c-1.94 0-4.365 1.385-5.362 3.095l-6.682 11.322c-.997 1.71-.215 3.095 1.751 3.095h31.713c1.967 0 3.072-.299 2.02 1.358L55.482 83.976c-1.051 1.656-.324 3.014 1.643 3.014h44.565c1.967 0 4.446-1.358 5.496-3.014l40.685-63.505c1.051-1.656.593-3.91-1.05-4.995L126.396 1.982C124.753.896 123.352.027 123.298.027L123.271 0Z"
    fill="currentColor"
  />
);

export const NUMBER_2 = () => (
  <path
    d="M176.669 43.4542C178.488 43.4542 186.118 44.4875 191.792 38.7226L202.164 22.9236C203.467 20.6938 203.196 19.0622 201.404 17.5938L177.294 3.18157C175.475 1.71315 172.162 0 170.343 0H55.1157C52.0476 0 50.1742 0.57105 48.328 4.32367L43.1422 14.521C42.2462 17.5122 43.7938 17.621 45.6129 17.621L156.198 17.7026L147.754 29.6402L28.7523 39.62C26.9332 39.62 26.0372 40.8165 24.6797 42.2849L10.2084 64.6375L1.33009 78.3699C-2.44385 83.4821 2.82337 86.99 4.66962 86.99H151.636C153.456 86.99 161.003 86.9356 164.452 81.6874L172.461 68.6076C172.027 65.698 169.23 64.8822 167.411 64.8822H61.9848L69.3698 53.2708L180.715 43.427H176.669V43.4542Z"
    fill="currentColor"
  />
);

export const NUMBER_3 = () => (
  <path
    d="M201.329 17.049L178.216 3.17634C176.431 1.71033 173.16 0 171.322 0H57.5914C55.7802 0 52.9416 0.461519 49.6165 5.29389L43.6691 14.4971C43.3177 16.8047 45.4263 17.7006 47.2375 17.7006H154.804L148.397 26.6595L94.7086 31.1661L69.0807 33.2565C66.3503 33.4737 63.2414 35.184 62.1601 37.0301L56.5641 46.5863C55.4828 48.4323 56.8615 50.1155 59.673 50.3056L108.334 53.5362C108.712 53.5362 109.09 53.5362 109.496 53.5362L113.659 53.8891L124.31 65.2913H15.6352C11.8505 65.2913 8.4983 67.3003 6.98442 70.8296L1.3344 80.4943C-3.28835 87.5528 5.49759 87.0642 6.57893 86.9284H156.913C160.292 86.5755 161.509 85.1909 162.86 83.6978L172.863 69.0378C174.215 66.7845 173.998 65.8886 171.998 63.6082L152.344 43.1656C151.371 42.3783 153.155 43.6814 152.128 43.1113L181.514 40.7222C188.84 39.4734 190.354 39.3648 193.841 35.3198L202.167 22.3158C203.465 20.0896 203.195 18.4608 201.41 16.9948L201.329 17.049Z"
    fill="currentColor"
  />
);

export const NUMBER_4 = () => (
  <path
    d="M147.754 0.216662C145.472 0.216662 143.923 2.27496 143.923 2.27496L117.045 43.7929L55.8166 44.0367L120.225 1.97705C120.225 1.97705 121.964 0 119.219 0C107.642 0.0541656 77.3131 0.216662 75.0575 0.216662C72.2583 0.216662 71.0082 1.21873 71.0082 1.21873C71.0082 1.21873 7.41533 42.3575 6.02933 43.2242C4.37157 44.2262 5.21407 45.6345 5.21407 45.6345L14.2095 65.1612C14.2095 65.1612 14.7801 66.6237 16.1118 66.6237H103.212L91.9069 84.065C91.9069 84.065 89.6784 86.99 93.2113 86.99H140.825C143.107 86.99 144.222 84.9588 144.222 84.9588L196.89 2.76245C196.89 2.76245 198.574 0.243745 194.525 0.243745H147.809L147.754 0.216662Z"
    fill="currentColor"
  />
);

export const NUMBER_5 = () => (
  <path
    d="M31.1332 43.1224C27.8603 42.606 25.9398 41.845 28.0497 38.2029C30.9169 33.2291 50.7709 1.53786 50.7709 1.53786C50.7709 1.53786 51.6635 0.0158122 54.2061 0.0158122H181.58C183.393 0.0158122 184.88 -0.310341 188.072 1.99991L202.462 14.4481C203.706 15.9158 202.814 17.2476 198.459 17.2476H93.4544L85.9888 29.6414L185.962 39.6162C187.775 39.6162 190.02 41.7906 188.261 43.9106L167.001 78.3741C164.431 83.4839 161.564 86.99 154.342 86.99H3.08333C1.27104 86.99 -1.46094 86.5551 0.946427 82.8859L11.5497 67.2034C12.9292 64.7844 14.7685 64.2136 16.6079 64.2136L123.966 64.268L131.161 52.7983L31.1603 43.1496L31.1332 43.1224Z"
    fill="currentColor"
  />
);

export const NUMBER_6 = () => (
  <path
    d="M195.445 14.2056L179.791 1.82543C176.45 -0.432924 175.184 0.0296311 173.325 0.0568402L53.1843 0.138468C49.8164 0.138468 47.7417 0.900323 45.4785 4.46472L7.29978 64.8962C7.29978 64.8962 6.11426 67.209 8.45833 68.515C10.8024 69.821 40.3324 86.6363 40.3324 86.6363C40.3324 86.6363 40.7904 86.99 41.8412 86.99C42.892 86.99 155.219 86.99 155.219 86.99C157.051 86.99 164.649 86.854 167.774 81.6298L169.202 79.0994H169.391L183.617 55.9988C184.803 53.7949 184.399 52.1895 182.512 50.7474L159.853 36.762C157.94 35.3471 154.518 33.6873 152.659 33.7145L78.5649 34.1499L88.7226 17.77L191.323 17.6067C195.742 17.6067 196.819 15.6749 195.472 14.2328L195.445 14.2056ZM134.446 50.9379L126.363 64.0527L59.9202 64.6513L68.2187 51.21L134.446 50.9379Z"
    fill="currentColor"
  />
);

export const NUMBER_7 = () => (
  <path
    d="M159.555 0H159.447C159.447 0 157.825 0 155.852 0H31.3105C29.3371 0 26.8501 1.35752 25.7958 3.0137L18.4157 14.4712C17.3614 16.1274 18.4158 17.6749 20.4163 17.6749L118.195 17.7292C120.168 17.7292 120.519 18.7338 118.979 19.9556L37.9336 84.7365C36.3927 85.9583 36.7441 86.99 38.7175 86.99H96.7575C98.7309 86.99 101.596 85.9854 103.11 84.7365L183.371 19.7112C184.912 18.4623 184.804 16.5618 183.155 15.4757L162.664 1.98198C161.015 0.895964 159.609 0.0271504 159.555 0.0271504V0Z"
    fill="currentColor"
  />
);

export const NUMBER_8 = () => (
  <path
    d="M200.284 17.5666L176.302 3.15437C174.492 1.71315 171.197 0 169.388 0H57.8488C56.0123 0 49.6386 1.54999 47.8021 5.30261L36.5402 23.5762C36.5402 23.5762 35.7569 24.9902 37.2963 26.1323C38.8357 27.2744 61.2786 41.9586 61.2786 41.9586L20.498 51.7752C20.498 51.7752 18.0134 52.4007 17.1221 53.6787C16.2309 54.9568 10.1543 64.6375 10.1543 64.6375L1.32306 78.3699C-2.43092 83.4821 2.8084 86.99 4.64488 86.99H150.834C152.644 86.99 160.151 86.9356 163.581 81.6874C163.581 81.6874 179.354 56.7787 180.245 55.2831C181.136 53.7875 180.38 53.0805 180.38 53.0805L160.908 41.9314C160.908 41.9314 195.855 27.8455 197.367 27.1657C198.88 26.4859 199.555 25.235 199.555 25.235L201.094 22.8692C202.391 20.6394 202.121 19.0078 200.338 17.5394L200.284 17.5666ZM124.097 64.9094H62.3319L71.0822 51.7752H132.442L124.097 64.9094ZM142.732 34.4262H81.858L92.3098 17.621H154.183L142.759 34.4262H142.732Z"
    fill="currentColor"
  />
);

export const NUMBER_9 = () => (
  <path
    d="M9.04599 68.515L22.7234 85.0877C24.4944 86.9322 27.6005 87.0135 29.4533 86.9864H146.475C148.328 86.9864 155.521 84.6809 157.155 80.9649L194.564 22.106C194.564 22.106 195.763 19.7733 193.393 18.4714C191.022 17.1694 163.504 0 163.504 0H47.7354C45.8827 0 38.1992 0.13562 35.0387 5.37054L19.1271 31.0026C17.9282 33.2268 18.3369 34.8271 20.2441 36.2376L43.6756 49.8809C45.6101 51.2913 47.4085 51.644 49.2612 51.644L122.853 51.21L114.461 64.9889L11.4164 65.0703C6.94803 65.0703 7.68369 67.0775 9.04599 68.5422V68.515ZM66.8893 34.3931L77.6242 17.5763L144.132 17.739L133.778 34.1219L66.8893 34.3931Z"
    fill="currentColor"
  />
);

interface NumberComponentProps {
  number: number;
}

const NumberComponentStyled = styled(Box, {
  aspectRatio: '203 / 87',
  color: '#D71920',
  svg: {
    height: '100%',
    width: 'auto',
  },
});
export const NumberComponent = ({ number }: NumberComponentProps) => {
  return (
    <NumberComponentStyled>
      <svg
        width="203"
        height="87"
        viewBox="0 0 203 87"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {number === 0 && <NUMBER_0 />}
        {number === 1 && <NUMBER_1 />}
        {number === 2 && <NUMBER_2 />}
        {number === 3 && <NUMBER_3 />}
        {number === 4 && <NUMBER_4 />}
        {number === 5 && <NUMBER_5 />}
        {number === 6 && <NUMBER_6 />}
        {number === 7 && <NUMBER_7 />}
        {number === 8 && <NUMBER_8 />}
        {number === 9 && <NUMBER_9 />}
      </svg>
    </NumberComponentStyled>
  );
};

const LOGO_WIDTH = 640;
const LOGO_HEIGHT = 216;

const LogoAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(0.8)',
  },
  '1%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1.0)',
  },
});
const LogoWrapper = styled(Box, {
  opacity: 0,
  maxWidth: 640,
  maxHeight: 'calc(100% - 224px)',
  aspectRatio: `${LOGO_WIDTH}/${LOGO_HEIGHT}`,
  picture: {
    display: 'block',
  },
  animationName: `${LogoAnimation}`,
  animationDuration: '3s',
  animationTimingFunction: 'ease',
  animationFillMode: 'both',
});

const TEXT_SPACING = 5;

const NumberAnimation = keyframes({
  '0%': {
    transform: 'translate(0,-100%)',
  },
  '100%': {
    transform: 'translate(0,0px)',
  },
});

const NumberAnimationLoop = keyframes({
  '0%': {
    transform: 'translate(0,-100%)',
  },
  '100%': {
    transform: `translate(0, ${TEXT_SPACING}px)`,
  },
});

const NumbersContainer = styled(Box, {
  '> *': {
    margin: `${TEXT_SPACING}px 0`,
  },
  '> *:last-child': {
    position: 'absolute',
    left: 0,
    top: '100%',
    width: '100%',
    height: 'auto',
    marginBottom: 0,
  },
});

const NumberWrapper = styled(Box, {
  width: ` ${(152 / LOGO_WIDTH) * 100}%`,
  height: `${(65 / LOGO_HEIGHT) * 100}%`,
  svg: {
    height: '100%',
    width: 'auto',
  },

  variants: {
    digit: {
      first: {
        left: `${(85 / LOGO_WIDTH) * 100}%`,
        top: `${(29 / LOGO_HEIGHT) * 100}%`,
        [`${NumbersContainer}`]: {
          animationName: `${NumberAnimation}`,
          animationDuration: '3s',
          animationTimingFunction: 'cubic-bezier(0.25, 1, 0.5, 1)',
          animationFillMode: 'both',
        },
      },
      last: {
        left: `${(250 / LOGO_WIDTH) * 100}%`,
        top: `${(29 / LOGO_HEIGHT) * 100}%`,
        [`${NumbersContainer}`]: {
          animationName: `${NumberAnimationLoop}`,
          animationDuration: '3s',
          animationTimingFunction: 'linear',
          animationFillMode: 'both',
          animationIterationCount: 'infinite',
        },
      },
    },
  },
});

const NumberOverflow = styled(Box, {
  position: 'absolute',
  width: '100%',
  height: `calc(100% + ${2 * TEXT_SPACING}px)`,
  top: `-${TEXT_SPACING}px`,

  maskImage:
    'linear-gradient(0deg, transparent, white 10%, white 90%, transparent)',
});

interface NumberWithAnimationProps {
  digit: VariantProps<typeof NumberComponentStyled>;
  numbers: number[];
}
const NumbersWithAnimation = ({ digit, numbers }: NumberWithAnimationProps) => {
  return (
    <NumberWrapper position="absolute" digit={digit}>
      <NumberOverflow>
        <NumbersContainer position="relative">
          {numbers.map((o, i) => (
            <NumberComponent key={i} number={o} />
          ))}
        </NumbersContainer>
      </NumberOverflow>
    </NumberWrapper>
  );
};

interface MaxforceLogoWithAnimationProps {
  logo?: ImageProps;
}

export const MaxforceLogoWithAnimation = ({
  logo,
}: MaxforceLogoWithAnimationProps) => {
  return (
    <LogoWrapper position="relative">
      <Box>
        <Image
          priority
          src={logo.src}
          width={LOGO_WIDTH}
          height={LOGO_HEIGHT}
          alt={logo.alt}
          useSrcSetPattern
        />

        <NumbersWithAnimation
          digit="first"
          numbers={[2, 1, 0, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]}
        />
        <NumbersWithAnimation
          digit="last"
          numbers={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0]}
        />
      </Box>
    </LogoWrapper>
  );
};
