export const FRAGMENT_SHADER = `
precision highp float;

#define iterations 1  // Reduce the number of iterations to improve performance
#define formuparam2 1.10 // Keeps formula parameter; higher values may simplify calculations
#define volsteps 3 // Reduce volume steps for sampling; lower this value for better performance
#define stepsize 0.79 // Increase step size for faster performance; larger steps can yield less detail but improve speed
#define zoom 9.60 // Reduce zoom to decrease the complexity of the scene
#define tile   0.9 // Adjust the tiling factor for texture to reduce complexity
#define speed2  0.1 // Reduce base speed for smoother animation on lower performance devices
#define distfading 0.9 // Adjust fading for visual quality without high computational cost
#define cloud 0.24 // Lower cloud density effect for better performance
#define range 30000.0 // Adjust the range for faster performance

uniform float iTime;
uniform vec2 iResolution;

float field(in vec3 p) {
    float strength = 5.0;
    float accum = 0.0;
    float prev = 0.0;
    float tw = 0.0;

    vec3 timeOffset = vec3(-0.5, -0.8 + 0.1 * sin(iTime * 0.2 + 2.0), -1.1 + 0.3 * cos(iTime * 0.15));

    for (int i = 0; i < 6; ++i) {
        float mag = dot(p, p);
        p = abs(p) / (mag + 0.001) + timeOffset;
        
        float w = 1.0 / (1.0 + float(i)); // Adjusted weight calculation for simplicity
        tw += w;
        
        float diff = abs(mag - prev);
        accum += w * exp(-strength * diff * diff);
        prev = mag;
    }

    return max(0.0, 3.0 * accum / tw - 0.4);
}

void main() {
    vec2 uv2 = 2. * gl_FragCoord.xy / iResolution.xy - 1.;
    vec2 uvs = uv2 * iResolution.xy / max(iResolution.x, iResolution.y);
    
    float angle2 = cos(iTime);
    float cosAngle = cos(angle2);
    float sinAngle = sin(angle2);
    mat2 rotateZ = mat2(cosAngle, -sinAngle, sinAngle, cosAngle);

    vec2 uv = rotateZ * uvs;

    float time2 = iTime;
    float speed = speed2;
    speed = 0.005 * cos(time2 * 0.02 + 3.1415926 / 4.0);
    float formuparam = formuparam2;

    float a_xz = 0.9 + 0.1 * sin(iTime * 0.1);
    float a_yz = -.6 + 0.05 * cos(iTime * 0.15);
    float a_xy = 0.9 + iTime * 0.04;

    mat2 rot_xz = mat2(cos(a_xz), sin(a_xz), -sin(a_xz), cos(a_xz));
    mat2 rot_yz = mat2(cos(a_yz), sin(a_yz), -sin(a_yz), cos(a_yz));
    mat2 rot_xy = mat2(cos(a_xy), sin(a_xy), -sin(a_xy), cos(a_xy));

    float v2 = 1.0;

    vec3 dir = vec3(uv * zoom, 1.0);
    vec3 from = vec3(0.0, 0.0, 0.0) + vec3(0.0, 0.0, (iTime - range) * speed); // Direct Z-axis movement only

    dir.xy = rot_xy * dir.xy;
    dir.xz = rot_xz * dir.xz;
    dir.yz = rot_yz * dir.yz;

    float sampleShift = mod((iTime - range) * speed, stepsize);
    float zoffset = -sampleShift;
    sampleShift /= stepsize;

    float s = 0.24;
    float s3 = s + stepsize * 0.5;
    vec3 v = vec3(0.0);
    float t3 = 0.0;

    vec3 backCol2 = vec3(0.15);

    vec3 tile2 = vec3(tile * 2.0);
    float zoomFactor = 1.9 / zoom;
    float sampleShiftFactor = 1.0 - sampleShift;
    float sampleShiftFinalFactor = sampleShift;

    for (int r = 0; r < volsteps; r++) {
        vec3 p2 = from + (s + zoffset) * dir;
        vec3 p3 = (from + (s3 + zoffset) * dir) * zoomFactor;

        p2 = abs(vec3(tile) - mod(p2, tile2));
        p3 = abs(vec3(tile) - mod(p3, tile2));

        float t3 = field(p3);
        float adjustedFade = pow(distfading, max(0.0, float(r) - sampleShift)) * 
            (r == 0 ? sampleShiftFactor : (r == volsteps - 1 ? sampleShiftFinalFactor : 1.0));
        backCol2 += vec3(0.65 * t3 * t3 * t3, 0.45 * t3 * t3, t3 * 1.3) * adjustedFade;

        float pa = 0.0, a = 0.0;
        vec3 p = p2;

        for (int i = 0; i < iterations; i++) {
            p = abs(p) / dot(p, p) - formuparam;
            float D = abs(length(p) - pa);

            if (i > 2) {
                a += i > 7 ? min(12.0, D) : D;
            }
            pa = length(p);
        }

        a *= a * a;
        v += adjustedFade * a;

        s += stepsize;
        s3 += stepsize;
    }

    vec4 forCol2 = vec4(v * .001, 1.0); // Reduced final brightness for performance

    backCol2 *= cloud;

    gl_FragColor = forCol2 + vec4(backCol2, 1.0);
}
`;

export const VERTEX_SHADER = `
attribute vec2 position;
void main() {
    gl_Position = vec4(position, 0, 1);
}
`;
