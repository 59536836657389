import React from 'react';

import { IconUero5Max } from '@/components/layouts/header/icon-uero-5-max';
import { Badge } from '@/components/layouts/header/submenu-model-link';
import { EngineType } from '@/components/sections/product-listing/product-item';
import { Icon } from '@/components/shared/element/icons';
import ClearIcon from '@/components/shared/element/icons/global/clear';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Flex } from '@/components/shared/layout/flex';
import { Grid } from '@/components/shared/layout/grid';
import { Image } from '@/components/shared/media/image';
import { styled } from '@/stitches.config';

export const ComparePanelContainer = styled(Box, {
  px: '$space-4',
  '@sm': {
    px: '$space-12',
  },
  '@lg': {
    maxWidth: 1080,
  },
});

export const ComparePanelDrag = styled(Box, {
  bottom: 'calc(100% - 1px)',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '$size-16',
  paddingBottom: 1,
  borderTopLeftRadius: '$rounded-1',
  borderTopRightRadius: '$rounded-1',
  '@lg': {
    display: 'none',
  },
});

export const ComparePanelDragInner = styled(Box, {
  rowGap: 3,
});

export const ComparePanelDragLine = styled(Box, {
  width: '100%',
  height: 1,
  bc: '$gray400',
  br: '$rounded-0-5',
});

export const ComparePanelItem = styled(Box, {
  minHeight: 60,
  pe: 'none',
  transitionProperty: 'transform, box-shadow',
  transitionDuration: 'var(--transition-duration)',
  transitionTimingFunction: 'var(--transition-easing)',
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pe: 'none',
    br: 'inherit',
    bc: '$gray200',
    linearGradient:
      'to right, $gray400 50%, rgba(255, 255, 255, 0) 0%), linear-gradient($gray400 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, $gray400 50%, rgba(255, 255, 255, 0) 0%), linear-gradient($gray400 50%, rgba(255, 255, 255, 0) 0%',
    backgroundPosition: 'top, right, bottom, left',
    backgroundRepeat: 'repeat-x, repeat-y',
    backgroundSize: '12px 1px, 1px 12px',
    transitionProperty: 'box-shadow',
    transitionDuration: 'var(--transition-duration)',
    transitionTimingFunction: 'var(--transition-easing)',
  },
  '&.is-active': {
    bs: '$shadow100',
    cursor: 'pointer',
    pe: 'auto',
    '&::before': {
      bc: '$white',
      bs: 'inset 0px 0px 0px 1px $primary',
    },
    '@hover': {
      '&:hover': {
        bs: '0px 16px 40px rgba(0, 0, 0, 0.24)',
        '& span': {
          color: '$primary',
        },
      },
    },
  },
});

const CustomBadge = styled(Badge, {
  position: 'relative',
  marginLeft: 0,
  width: '100%',
  maxWidth: 80,
});

export const ComparePanelItems = styled(Grid, {
  gridTemplateColumns: 'repeat(2, 1fr)',
  '@lg': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: '100%',
  },
  '@maxlg': {
    [`& ${ComparePanelItem}`]: {
      '&:nth-child(3)': {
        display: 'none',
      },
    },
  },
  '&.is-hide': {
    '@maxlg': {
      display: 'none',
    },
  },
});

export const ComparePanelItemImage = styled(Box, {
  flex: '0 0 48%',
  maxWidth: 85,
});

export const ComparePanelButtons = styled(Flex, {
  '@lg': {
    flex: '0 0 136px',
    maxWidth: '136px',
  },
});

export const ComparePanel = styled(Box, {
  width: 'calc(100vw - var(--scrollbar-width))',
  py: '$space-4',
  zIndex: '$panel',
  pe: 'none',
  transition: 'transform var(--transition-duration) var(--transition-easing)',
  '@lg': {
    py: '$space-3',
    minHeight: 94,
    filter: 'drop-shadow(0px 16px 40px rgba(0, 0, 0, 0.24))',
    transform: 'translateY(100%)',
  },
  '@maxlg': {
    filter: 'drop-shadow(0px -2px 16px rgba(0, 0, 0, 0.24))',
    transform: 'translateY(calc(100% + 20px))',
  },
  '&.is-active': {
    pe: 'auto',
    transform: 'translateY(0)',
  },
});

const ModelNameText = styled(Text, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

interface CompareItemViewProps {
  image?: string;
  title?: string;
  engineType?: EngineType;
}

export const CompareItemView = (props: CompareItemViewProps) => {
  return (
    <>
      {!!props.image && !!props.title && (
        <ComparePanelItemImage>
          <Image
            src={props.image}
            alt={props.title}
            width={600}
            height={450}
            layout="responsive"
            aspectRatio="4by3"
            sources={[
              {
                srcset: props.image,
                options: {
                  width: 96,
                  crop: '4:3',
                },
              },
            ]}
          />
        </ComparePanelItemImage>
      )}
      <Grid rowGap="1" alignItems="start">
        {!!props.title && (
          <ModelNameText size="text-body-1" font="bold" noWrap>
            {props.title}
          </ModelNameText>
        )}
        {!!props.engineType && props.engineType === 'euro-5' && (
          <CustomBadge>
            <IconUero5Max />
          </CustomBadge>
        )}
      </Grid>
      {!!props.title && !!props.image && (
        <Box position="absolute" top="0" right="0" margin="1" fontSize="0">
          <Icon size="xs" color="icon-red">
            <ClearIcon />
          </Icon>
        </Box>
      )}
    </>
  );
};
