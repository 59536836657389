import React from 'react';

import {
  CompareItemView,
  ComparePanel,
  ComparePanelButtons,
  ComparePanelContainer,
  ComparePanelDrag,
  ComparePanelDragInner,
  ComparePanelDragLine,
  ComparePanelItem,
  ComparePanelItems,
} from '@/components/sections/product-listing/product-compare-panel/styled';
import { ProductItemCardProps } from '@/components/sections/product-listing/product-item';
import { Button } from '@/components/shared/buttons/button';
import { Flex } from '@/components/shared/layout/flex';
import {
  CARD_CANCEL_BUTTON_LABEL,
  CARD_COMPARE_BUTTON_LABEL,
} from '@/contents/cv/product';

interface Props {
  productList: ProductItemCardProps[];
  selectedCompare: {
    selections: string[];
  };
  compareResultData: string[];
  showComparePanel: boolean;
  showCompareItemsMobile: boolean;
  enableCompareButton: boolean;
  handleCompareChange: (id: string) => void;
  setShowCompareItemsMobile: (value: boolean) => void;
  onCompare: () => void;
  onClear: () => void;
}

const View: React.FC<Props> = ({
  productList,
  selectedCompare,
  compareResultData,
  showComparePanel,
  showCompareItemsMobile,
  enableCompareButton,
  handleCompareChange,
  setShowCompareItemsMobile,
  onCompare,
  onClear,
}) => {
  return (
    <ComparePanel
      position="fixed"
      left="0"
      bottom="0"
      backgroundColor="white"
      className={showComparePanel ? 'is-active' : ''}
      test={{
        dataTest: 'panel_compare',
      }}
    >
      <ComparePanelContainer width="full" marginX="auto">
        <Flex
          direction={{ '@initial': 'column', '@lg': 'row' }}
          justifyContent={{ '@initial': 'center', '@lg': 'between' }}
          alignItems={{ '@initial': 'stretch', '@lg': 'center' }}
          columnGap="12"
          rowGap="3"
        >
          <ComparePanelItems
            columnGap={{ '@initial': 3, '@lg': 4 }}
            className={showCompareItemsMobile ? '' : 'is-hide'}
          >
            {[
              ...selectedCompare.selections,
              ...Array(3 - selectedCompare.selections.length).fill(null),
            ].map((o, i) => {
              return (
                <ComparePanelItem
                  key={i}
                  position="relative"
                  paddingY="1"
                  paddingLeft="2"
                  paddingRight="5"
                  display="flex"
                  alignItems="center"
                  columnGap={{ '@initial': '2', '@sm': '3' }}
                  rounded={{ '@initial': '1', '@lg': '1-5' }}
                  userSelect="none"
                  className={o ? 'is-active' : ''}
                  onClick={() => handleCompareChange(o)}
                  tracking={{
                    dataTrack: 'product-list-compare',
                    dataTrackText: `Compare Model ${i}`,
                    dataTrackValue: productList.find((item) => item.uid === o)
                      ?.title,
                  }}
                  test={{
                    dataTest: `compare_model_${i}`,
                  }}
                >
                  <CompareItemView
                    title={productList.find((item) => item.uid === o)?.title}
                    image={productList.find((item) => item.uid === o)?.img.url}
                    engineType={
                      productList.find((item) => item.uid === o)?.engineType
                    }
                  />
                </ComparePanelItem>
              );
            })}
          </ComparePanelItems>
          <ComparePanelButtons direction="column" rowGap="2">
            <Button
              css={{
                '@lg': {
                  display: 'none',
                },
              }}
              size="md"
              disabled={!enableCompareButton ? true : false}
              onClick={onCompare}
              label={CARD_COMPARE_BUTTON_LABEL}
              tracking={{
                dataTrack: 'product-list-compare-cta',
                dataTrackText: CARD_COMPARE_BUTTON_LABEL,
                dataTrackValue: compareResultData.toString(),
              }}
              test={{
                dataTest: 'button_compare',
              }}
            />
            <Button
              css={{
                '@maxlg': {
                  display: 'none',
                },
              }}
              size="sm"
              disabled={!enableCompareButton ? true : false}
              onClick={onCompare}
              label={CARD_COMPARE_BUTTON_LABEL}
              tracking={{
                dataTrack: 'product-list-compare-cta',
                dataTrackText: CARD_COMPARE_BUTTON_LABEL,
                dataTrackValue: compareResultData.toString(),
              }}
              test={{
                dataTest: 'button_compare',
              }}
            />
            <Button
              css={{
                '@maxlg': {
                  display: 'none',
                },
              }}
              border
              size="sm"
              label={CARD_CANCEL_BUTTON_LABEL}
              onClick={onClear}
              tracking={{
                dataTrack: 'product-list-compare-cancel',
                dataTrackText: CARD_CANCEL_BUTTON_LABEL,
              }}
              test={{
                dataTest: 'button_cancelCompare',
              }}
            />
          </ComparePanelButtons>
        </Flex>
      </ComparePanelContainer>
      <ComparePanelDrag
        position="absolute"
        display={{ '@initial': 'flex', '@lg': 'none' }}
        direction="column"
        alignItems="center"
        justifyContent="end"
        backgroundColor="white"
        paddingTop="2"
        cursor="pointer"
        onClick={() => setShowCompareItemsMobile(!showCompareItemsMobile)}
      >
        <ComparePanelDragInner display="flex" direction="column" width="5">
          <ComparePanelDragLine />
          <ComparePanelDragLine />
          <ComparePanelDragLine />
        </ComparePanelDragInner>
      </ComparePanelDrag>
    </ComparePanel>
  );
};

View.displayName = 'ProductComparePanel';
export default View;
