import React from 'react';

import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';

interface Props {
  total: number;
}

const FILTER_NUMBER_OF_RESULTS_LABEL = 'ค้นหาตัวเลือก';
const FILTER_NUMBER_OF_RESULTS_UNIT_LABEL = 'รายการ';

const View: React.FC<Props> = ({ total }) => {
  return (
    <Box paddingY="4">
      <Text as="p" size="text-body-2" data-test="total_filterResult">
        {FILTER_NUMBER_OF_RESULTS_LABEL} ({total}{' '}
        {FILTER_NUMBER_OF_RESULTS_UNIT_LABEL})
      </Text>
    </Box>
  );
};

View.displayName = 'ProductListingTotal';
export default View;
