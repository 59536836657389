'use client';

import { Camera, Mesh, Plane, Program, Renderer, Transform } from 'ogl';
import { useEffect, useRef, useState } from 'react';

import { styled } from '@/stitches.config';

import { FRAGMENT_SHADER, VERTEX_SHADER } from './shader';

const CanvasStyled = styled('canvas', {
  opacity: 0,
  variants: {
    active: {
      true: {
        opacity: 1,
        transition: 'opacity 1s ease',
      },
    },
  },
});

export const NebularBackground = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const rendererRef = useRef<Renderer | null>(null);
  const rafRef = useRef<number | null>(null);
  const lastTimeRef = useRef<number>(0);
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (!canvasRef.current) return;

    rendererRef.current = new Renderer({
      canvas: canvasRef.current,
      width: window.innerWidth,
      height: window.innerHeight,
      dpr: window.devicePixelRatio, // Reduce DPR if necessary for performance: Math.min(window.devicePixelRatio, 1.5)
    });

    const { gl } = rendererRef.current;
    gl.clearColor(1, 1, 1, 1);

    const camera = new Camera(gl, { fov: 35 });
    camera.position.set(0, 0, 100);

    const scene = new Transform();

    const fitPlaneToView = (camera: Camera) => {
      const distance = camera.position.z;
      const aspect = gl.canvas.width / gl.canvas.height;
      const vFOV = (camera.fov * Math.PI) / 180;

      const planeHeight = 2 * Math.tan(vFOV / 2) * distance;
      const planeWidth = planeHeight * aspect;

      return { planeWidth, planeHeight };
    };

    const { planeWidth, planeHeight } = fitPlaneToView(camera);
    const geometry = new Plane(gl, { width: planeWidth, height: planeHeight });
    const program = new Program(gl, {
      vertex: VERTEX_SHADER,
      fragment: FRAGMENT_SHADER,
      uniforms: {
        iTime: { value: 0 },
        iResolution: { value: [gl.canvas.width, gl.canvas.height] },
        angle: { value: 0 },
      },
    });

    const mesh = new Mesh(gl, { geometry, program });
    mesh.setParent(scene);

    const update = (t: number) => {
      const deltaTime = t - lastTimeRef.current;
      if (deltaTime > 1000 / 60) {
        // Throttling to 60 FPS
        lastTimeRef.current = t;

        if (rendererRef.current) {
          program.uniforms.iTime.value = t * 0.0002;
          rendererRef.current.render({ scene, camera });
        }
      }

      rafRef.current = requestAnimationFrame(update);
    };

    rafRef.current = requestAnimationFrame(update);
    setActive(true);

    const handleResize = () => {
      if (rendererRef.current && canvasRef.current) {
        rendererRef.current.setSize(
          canvasRef.current.parentElement.offsetWidth,
          canvasRef.current.parentElement.offsetHeight
        );
        camera.perspective({
          aspect:
            canvasRef.current.parentElement.offsetWidth /
            canvasRef.current.parentElement.offsetHeight,
        });

        const { planeWidth, planeHeight } = fitPlaneToView(camera);
        mesh.geometry = new Plane(gl, {
          width: planeWidth,
          height: planeHeight,
        });

        program.uniforms.iResolution.value = [
          gl.canvas.width,
          gl.canvas.height,
        ];
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      if (rafRef.current) cancelAnimationFrame(rafRef.current);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CanvasStyled
      active={active}
      ref={canvasRef}
      css={{ width: '100%', height: '100%' }}
    />
  );
};
