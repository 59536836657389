const IconSpecEngine: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.2609 9.93232C19.9804 9.93232 19.784 10.1644 19.784 10.4255V13.646H18.6059V12.2244C18.6059 11.0638 17.7082 10.1064 16.5582 10.1064H15.4923C14.9594 10.1064 14.5105 9.6712 14.5386 9.11992C14.5386 8.56864 14.1178 8.10448 13.5849 8.10448H12.2384V6.9439H14.0617C14.3422 6.9439 14.5386 6.7118 14.5386 6.45068V5.49323C14.5386 5.2031 14.3142 5 14.0617 5H6.88078C6.60028 5 6.40393 5.23211 6.40393 5.49323V6.45068C6.40393 6.74082 6.62833 6.9439 6.88078 6.9439H8.53576V8.10448H7.04909C5.92707 8.10448 5.0014 9.03288 5.0014 10.2224V11.5281H3.87938V9.55512C3.87938 9.26496 3.65498 9.06192 3.40253 9.06192H2.47686C2.19635 9.06192 2 9.294 2 9.55512V16.5474C2 16.8375 2.2244 17.0406 2.47686 17.0406H3.40253C3.68303 17.0406 3.87938 16.8085 3.87938 16.5474V13.9652H5.0014V15.8801C5.0014 17.0406 5.89902 17.9981 7.04909 17.9981H9.48952C10.0225 17.9981 10.4713 18.4333 10.4432 18.9846C10.4432 19.5358 10.8639 20 11.3969 20H16.5302C17.6522 20 18.5778 19.0716 18.5778 17.882V16.1122H19.756V19.3327C19.756 19.6228 19.9804 19.8259 20.2328 19.8259C21.2146 19.8259 22 19.0135 22 17.9981V11.7311C22 10.7447 21.2146 9.93232 20.2609 9.93232Z" />
  </svg>
);
IconSpecEngine.displayName = 'IconSpecEngine';
export default IconSpecEngine;
