import React from 'react';

import { Text } from '@/components/shared/element/text';
import { styled } from '@/stitches.config';
import { IThemeLCV } from '@/types/shared';
import { ITest } from '@/types/tracking';

const StyledLabel = styled('label', {});

interface LabelProps extends React.ComponentProps<typeof StyledLabel> {
  children?: React.ReactNode;
  htmlFor?: string;
  label?: string;
  secondaryLabel?: string;
  required?: boolean;
  theme?: IThemeLCV;
  test?: ITest;
  weight?: 'regular' | 'strong';
}

export const Label: React.FC<LabelProps> = ({
  children,
  htmlFor,
  label,
  secondaryLabel,
  required = false,
  theme,
  test,
  weight = 'regular',
  ...rest
}: LabelProps) => {
  const baseTextStyle = {
    display: 'inline',
    marginLeft: '$space-1',
  };
  return (
    <StyledLabel
      htmlFor={htmlFor}
      data-test={!!test?.dataTest && `label_${test?.dataTest}`}
      {...rest}
    >
      <Text
        font={weight === 'regular' ? 'base' : 'bold'}
        variant={theme === 'dark' ? 'text-white' : 'text-gray-darkest'}
      >
        {label}
        {children}
        {!!secondaryLabel && (
          <Text
            variant={theme === 'dark' ? 'text-white' : 'text-gray'}
            css={baseTextStyle}
            data-test={!!test?.dataTest && `secondaryLabel_${test?.dataTest}`}
          >
            {secondaryLabel}
          </Text>
        )}
        {!!required && (
          <Text variant="text-red" css={baseTextStyle} data-test="required">
            *
          </Text>
        )}
      </Text>
    </StyledLabel>
  );
};

Label.displayName = 'Label';
