import React from 'react';

import { Text } from '@/components/shared/element/text';
import { Flex } from '@/components/shared/layout/flex';
import { ITest } from '@/types/tracking';

interface FieldMessageProps {
  message: string;
  error?: boolean;
  test?: ITest;
}

export const FieldMessage: React.FC<FieldMessageProps> = ({
  message,
  error = false,
  test,
}: FieldMessageProps) => {
  return (
    <Flex gap="1">
      <Text
        size="text-caption"
        variant={error ? 'text-red' : 'text-gray'}
        data-test={!!test?.dataTest && `message_${test?.dataTest}`}
      >
        {message}
      </Text>
    </Flex>
  );
};
FieldMessage.displayName = 'FieldMessage';
