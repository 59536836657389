import { SplashPrelaunchProps } from '@/components/sections/splash-prelaunch';

export const META_TAG_DATA = {
  title: 'ลงทะเบียนทดลองขับก่อนใคร - อีซูซุประเทศไทย Isuzu Thailand Official',
  description: 'กรอกข้อมูลของท่าน เพื่อให้เจ้าหน้าที่ติดต่อกลับโดยเร็วที่สุด',
  img: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcb45994432d3915a/6724456a7082177ce5cf7954/ddi-max-force-og.jpg',
  },
};

export const SPLASH_PRELAUNCH_DATA: SplashPrelaunchProps = {
  logo: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta4817513a7a56d5f/67287e6099511e56858feb3b/engine-logo.svg',
    alt: 'Ddi MAXFORCE พลังใหม่...กำหนดโลก!',
    width: 640,
    height: 179,
  },
  headline: {
    buttons: [
      {
        label: 'ลงทะเบียนทดลองขับ',
        url: '/register/maxforce',
        color: 'button-red',
      },
      {
        label: 'เข้าสู่เว็บไซต์',
        color: 'button-white',
        border: true,
      },
    ],
  },
};
