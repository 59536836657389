import React, { ChangeEventHandler } from 'react';

import { Text } from '@/components/shared/element/text';
import { styled } from '@/stitches.config';
import { ITest, ITracking } from '@/types/tracking';

export const CustomCheckboxGroup = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$space-3',
});

const StyledLabelInner = styled('div', {
  position: 'relative',
  '& > *': {
    '&:nth-child(1)': {
      position: 'relative',
      opacity: 0,
      visibility: 'hidden',
      pointerEvents: 'none',
    },
    '&:nth-child(2)': {
      position: 'absolute',
      left: '$space-0',
      right: '$space-0',
      top: '$space-0',
      bottom: '$space-0',
      textAlign: 'center',
    },
  },
});

const StyledLabel = styled('label', {
  position: 'relative',
  borderRadius: '$rounded-1',
  py: '$space-1',
  px: '$space-4',
  userSelect: 'none',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '$space-0',
    right: '$space-0',
    top: '$space-0',
    bottom: '$space-0',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 'inherit',
    transitionProperty: 'border-color, background-color',
    transitionDelay: 'var(--transition-delay)',
    transitionDuration: 'var(--transition-duration)',
    transitionTimingFunction: 'var(--transition-easing)',
    pointerEvents: 'none',
  },
});

const StyledInput = styled('input', {
  all: 'unset',
  position: 'absolute',
  left: '$space-0',
  right: '$space-0',
  top: '$space-0',
  bottom: '$space-0',
  zIndex: 1,
  cursor: 'pointer',
  '&:checked': {
    [`& ~ ${StyledLabel}`]: {
      '& span': {
        color: '$primary',
        fontFamily: '$bold',
      },
    },
  },
});

const CustomCheckboxGroupItem = styled('div', {
  position: 'relative',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  variants: {
    variant: {
      gray: {
        [`& ${StyledLabel}`]: {
          '&::before': {
            borderColor: 'var(--input-stoke-color)',
            backgroundColor: '$white',
          },
        },
        [`& ${StyledInput}`]: {
          '&:checked': {
            [`& ~ ${StyledLabel}`]: {
              '&::before': {
                borderColor: '$primary',
                backgroundColor: '$primary-200',
              },
              '& span': {
                color: '$primary',
              },
            },
          },
        },
        '@hover': {
          '&:hover': {
            [`& ${StyledLabel}`]: {
              '&::before': {
                borderColor: 'var(--input-stoke-color-focus)',
                backgroundColor: '$gray200',
              },
            },
          },
        },
      },
      white: {
        [`& ${StyledLabel}`]: {
          '&::before': {
            borderColor: 'var(--input-stoke-color)',
            backgroundColor: '$transparent',
          },
        },
        [`& ${StyledInput}`]: {
          '&:checked': {
            [`& ~ ${StyledLabel}`]: {
              '&::before': {
                borderColor: '$white',
                backgroundColor: '$white',
              },
              '& span': {
                color: '$gray900',
              },
            },
          },
        },
        '@hover': {
          '&:hover': {
            [`& ${StyledLabel}`]: {
              '&::before': {
                borderColor: '$white',
                backgroundColor: '$gray700',
              },
            },
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'gray',
  },
});

type IProps = React.ComponentProps<typeof CustomCheckboxGroupItem> & {
  name?: string;
  value?: string | number;
  label?: string;
  handleOnChange?: ChangeEventHandler;
  selected?: boolean;
  tracking?: ITracking;
  test?: ITest;
};

export const CustomCheckbox: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const options = {
    variant: props.variant,
  };
  return (
    <CustomCheckboxGroupItem {...options}>
      <StyledInput
        id={props.name}
        name={props.name}
        type="checkbox"
        checked={props.selected}
        onChange={props.handleOnChange}
        data-track={!!props.tracking && props.tracking.dataTrack}
        data-track-value={!!props.tracking && props.tracking.dataTrackValue}
        data-track-text={!!props.tracking && props.tracking.dataTrackText}
        data-track-url={!!props.tracking && props.tracking.dataTrackUrl}
        data-test={!!props.test && props.test.dataTest}
      />
      <StyledLabel htmlFor={props.name}>
        <StyledLabelInner>
          <Text
            font="bold"
            variant={
              props.variant === 'white' ? 'text-white' : 'text-gray-darkest'
            }
          >
            {props.label}
          </Text>
          <Text
            variant={
              props.variant === 'white' ? 'text-white' : 'text-gray-darkest'
            }
          >
            {props.label}
          </Text>
        </StyledLabelInner>
      </StyledLabel>
    </CustomCheckboxGroupItem>
  );
};
CustomCheckbox.displayName = 'SharedFormCustomCheckbox';
