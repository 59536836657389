import { useEffect, useRef } from 'react';

const useCheckInitialRender = () => {
  const isInitialRender = useRef(true);

  useEffect(() => {
    // This effect will run after the first render
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, []);

  return isInitialRender.current;
};

export default useCheckInitialRender;
