import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import useCheckInitialRender from '@/components/hooks/useCheckInitialRender';
import { useLocalStorage } from '@/components/hooks/useLocalStorage';
import NotFound from '@/components/sections/product-listing/not-found';
import ProductComparePanel from '@/components/sections/product-listing/product-compare-panel';
import ProductItem, {
  ISelect,
  ProductItemCardProps,
} from '@/components/sections/product-listing/product-item';
import ProductListingTotal from '@/components/sections/product-listing/product-listing-total';
import {
  ProductList,
  ProductListContent,
  ProductListContentItems,
  ProductListFilterPanel,
  ProductListFilterPanelBody,
  ProductListFilterPanelBodyScroll,
  ProductListFilterPanelOuter,
  ProductListFilterPanelOverlay,
  ProductListResetFilter,
} from '@/components/sections/product-listing/styled';
import { ButtonIcon } from '@/components/shared/buttons/button-icon';
import { Divider } from '@/components/shared/element/divider';
import { Icon } from '@/components/shared/element/icons';
import { Text } from '@/components/shared/element/text';
import {
  CustomCheckbox,
  CustomCheckboxGroup,
} from '@/components/shared/form/custom-checkbox';
import { Select } from '@/components/shared/form/select';
import { HeadingLevel1 } from '@/components/shared/headline/heading-level-1';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { Flex } from '@/components/shared/layout/flex';
import { Grid } from '@/components/shared/layout/grid';
import { Section } from '@/components/shared/layout/section';
import { useWindowSize } from '@/components/shared/utility/hooks';
import { trackProductListCompare, trackProductListFilter } from '@/tag-manager';
import { IButton } from '@/types/shared';
import { IIcon } from '@/types/shared/icons';

export interface SectionProductListingProps {
  title?: string;
  description?: string;
  buttons?: IButton[];
  filterResultTitle?: string;
  filterResultClearButtonLabel?: string;
  filterPanelTitle?: string;
  filterPanelIcon?: IIcon;
  remarkLabel?: string;
  notFoundLabel?: string;
  modelSubCategoryLabel?: string;
  modelSubCategoryPlaceholder?: string;
  modelSubCategory?: ISelect[];
  businessTypeLabel?: string;
  businessTypePlaceholder?: string;
  businessType?: ISelect[];
  segmentTypeLabel?: string;
  segmentTypePlaceholder?: string;
  segmentType?: ISelect[];
  bodyTypeLabel?: string;
  bodyTypePlaceholder?: string;
  bodyType?: ISelect[];
  cardCompareButtonIcon?: IIcon;
  cardDetailButtonIcon?: IIcon;
  cardCancelButtonIcon?: IIcon;
  cardModelClassItems?: ProductItemCardProps[];
  sortProductCard?: {
    uid: string;
  }[];
  sortProductSubCategory?: {
    uid: string;
  }[];
  sortProductBusinessType?: {
    uid: string;
  }[];
  sortProductSegmentType?: {
    uid: string;
  }[];
  sortProductBodyType?: {
    uid: string;
  }[];
}
export const CV_PRODUCT_ENGINE_TYPE_LOCAL_STORAGE_KEY =
  'cv-product-engine-type';
export const CV_PRODUCT_SUB_CATEGORY_LOCAL_STORAGE_KEY =
  'cv-product-sub-category';
export const CV_PRODUCT_BUSINESS_TYPE_LOCAL_STORAGE_KEY =
  'cv-product-business-type';
export const CV_PRODUCT_SEGMENT_TYPE_LOCAL_STORAGE_KEY =
  'cv-product-segment-type';
export const CV_PRODUCT_BODY_TYPE_LOCAL_STORAGE_KEY = 'cv-product-body-type';
export const CV_PRODUCT_MODEL_1_LOCAL_STORAGE_KEY = 'cv-product-model-1';
export const CV_PRODUCT_MODEL_2_LOCAL_STORAGE_KEY = 'cv-product-model-2';
export const CV_PRODUCT_MODEL_3_LOCAL_STORAGE_KEY = 'cv-product-model-3';
export const CV_PRODUCT_SELECTED_COMPARE_LOCAL_STORAGE_KEY =
  'cv-product-selected-compare';

export const clearCvProductList = () => {
  if (localStorage) {
    localStorage.removeItem(CV_PRODUCT_ENGINE_TYPE_LOCAL_STORAGE_KEY);
    localStorage.removeItem(CV_PRODUCT_SUB_CATEGORY_LOCAL_STORAGE_KEY);
    localStorage.removeItem(CV_PRODUCT_BUSINESS_TYPE_LOCAL_STORAGE_KEY);
    localStorage.removeItem(CV_PRODUCT_SEGMENT_TYPE_LOCAL_STORAGE_KEY);
    localStorage.removeItem(CV_PRODUCT_BODY_TYPE_LOCAL_STORAGE_KEY);
    localStorage.removeItem(CV_PRODUCT_MODEL_1_LOCAL_STORAGE_KEY);
    localStorage.removeItem(CV_PRODUCT_MODEL_2_LOCAL_STORAGE_KEY);
    localStorage.removeItem(CV_PRODUCT_MODEL_3_LOCAL_STORAGE_KEY);
    localStorage.removeItem(CV_PRODUCT_SELECTED_COMPARE_LOCAL_STORAGE_KEY);
  }
};

const View: React.FC<SectionProductListingProps> = (props) => {
  const windowSize = useWindowSize();
  const router = useRouter();

  const modelClassList = props.cardModelClassItems;
  const modelSubCategoryList = props.modelSubCategory;
  const businessTypeList = props.businessType;
  const segmentTypeList = props.segmentType;
  const bodyTypeList = props.bodyType;

  //
  // Filter Panel
  //
  const modelSubCategorySort = props.sortProductSubCategory.map((o) =>
    modelSubCategoryList.find((j) => j.uid === o.uid)
  );
  modelSubCategorySort.push(
    ...modelSubCategoryList.filter(
      (o) => !props.sortProductSubCategory.find((s) => s.uid === o.uid)
    )
  );

  const businessTypeSort = props.sortProductBusinessType.map((o) =>
    businessTypeList.find((j) => j.uid === o.uid)
  );
  businessTypeSort.push(
    ...businessTypeList.filter(
      (o) => !props.sortProductBusinessType.find((s) => s.uid === o.uid)
    )
  );

  const segmentTypeSort = props.sortProductSegmentType.map((o) =>
    segmentTypeList.find((j) => j.uid === o.uid)
  );
  segmentTypeSort.push(
    ...segmentTypeList.filter(
      (o) => !props.sortProductSegmentType.find((s) => s.uid === o.uid)
    )
  );

  const bodyTypeSort = props.sortProductBodyType.map((o) =>
    bodyTypeList.find((j) => j.uid === o.uid)
  );
  bodyTypeSort.push(
    ...bodyTypeList.filter(
      (o) => !props.sortProductBodyType.find((s) => s.uid === o.uid)
    )
  );

  const productList = modelClassList.filter((o) => o);

  // const productList = props.cardModelClassItems;
  const [openFilterPanel, setOpenFilterPanel] = useState<boolean>(false);

  // List of all Product satisfing all the filters
  const [filteredList, setFilteredList] =
    useState<ProductItemCardProps[]>(productList);

  const [checkedEngineCategory, setCheckedEngineCategory] = useLocalStorage(
    CV_PRODUCT_ENGINE_TYPE_LOCAL_STORAGE_KEY,
    { selections: [] }
  );

  const [checkedModelSubCategory, setCheckedModelSubCategory] = useLocalStorage(
    CV_PRODUCT_SUB_CATEGORY_LOCAL_STORAGE_KEY,
    { selections: [] }
  );
  const [selectedBusinessType, setSelectedBusinessType] = useLocalStorage(
    CV_PRODUCT_BUSINESS_TYPE_LOCAL_STORAGE_KEY,
    ''
  );
  // Selected Segment type filter
  const [selectedSegmentType, setSelectedSegmentType] = useLocalStorage(
    CV_PRODUCT_SEGMENT_TYPE_LOCAL_STORAGE_KEY,
    ''
  );
  // Selected Body type filter
  const [selectedBodyType, setSelectedBodyType] = useLocalStorage(
    CV_PRODUCT_BODY_TYPE_LOCAL_STORAGE_KEY,
    ''
  );

  const filterByEngineType = (filteredData) => {
    // Avoid filter for empty string
    if (
      !checkedEngineCategory ||
      checkedEngineCategory.selections.length === 0
    ) {
      return filteredData;
    }

    const filteredProducts = filteredData.filter((product) => {
      return checkedEngineCategory.selections.some((selectedEngineType) => {
        return selectedEngineType === product.engineType;
      });
    });

    return filteredProducts;
  };

  const filterByModelClass = (filteredData) => {
    // Avoid filter for empty string
    if (
      !checkedModelSubCategory ||
      checkedModelSubCategory.selections.length === 0
    ) {
      return filteredData;
    }

    const filteredProducts = filteredData.filter((product) => {
      return checkedModelSubCategory.selections.includes(
        product.subCategoryId[0].uid
      );
    });

    return filteredProducts;
  };

  const filterByBusinessType = (filteredData) => {
    // Avoid filter for empty string
    if (!selectedBusinessType) {
      return filteredData;
    }

    const filteredProducts = filteredData.filter((product) => {
      let isMatch = false;
      product.businessTypeId.forEach((b) => {
        if (b.uid == selectedBusinessType) {
          isMatch = true;
        }
      });
      return isMatch;
    });

    return filteredProducts;
  };

  const filterBySegmentType = (filteredData) => {
    if (!selectedSegmentType) {
      return filteredData;
    }
    const filteredProducts = filteredData.filter((product) => {
      let isMatch = false;
      product.segmentTypeId.forEach((b) => {
        if (b.uid == selectedSegmentType) {
          isMatch = true;
        }
      });
      return isMatch;
    });

    return filteredProducts;
  };

  const filterByBodyType = (filteredData) => {
    if (!selectedBodyType) {
      return filteredData;
    }
    const filteredProducts = filteredData.filter((product) => {
      let isMatch = false;
      product.bodyTypeId.forEach((b) => {
        if (b.uid == selectedBodyType) {
          isMatch = true;
        }
      });
      return isMatch;
    });
    return filteredProducts;
  };

  const handleCheckboxChange = (event, key: string, label: string) => {
    const sel = checkedModelSubCategory.selections;
    const find = sel.indexOf(key);
    if (find > -1) {
      sel.splice(find, 1);
    } else {
      sel.push(key);
    }

    setCheckedModelSubCategory({
      selections: sel,
    });

    if (event.target.checked === true) {
      trackProductListFilter({
        modelClass: '',
        modelSubCategory: label,
        businessType: '',
        segmentType: '',
        bodyType: '',
      });
    }
  };

  const handleBusinessTypeChange = (event) => {
    const select = event.target;
    const value = select.value;
    const label = select.options[select.selectedIndex].text;
    setSelectedBusinessType(value);
    trackProductListFilter({
      modelClass: '',
      modelSubCategory: '',
      businessType: label,
      segmentType: '',
      bodyType: '',
    });
  };

  const handleSegmentTypeChange = (event) => {
    const select = event.target;
    const value = select.value;
    const label = select.options[select.selectedIndex].text;
    setSelectedSegmentType(value);
    trackProductListFilter({
      modelClass: '',
      modelSubCategory: '',
      businessType: '',
      segmentType: label,
      bodyType: '',
    });
  };

  const handleBodyTypeChange = (event) => {
    const select = event.target;
    const value = select.value;
    const label = select.options[select.selectedIndex].text;
    setSelectedBodyType(value);
    trackProductListFilter({
      modelClass: '',
      modelSubCategory: '',
      businessType: '',
      segmentType: '',
      bodyType: label,
    });
  };

  const _onClickClearFilter = () => {
    setCheckedEngineCategory({ selections: [] });
    setCheckedModelSubCategory({ selections: [] });
    setSelectedBusinessType('');
    setSelectedSegmentType('');
    setSelectedBodyType('');
  };

  useEffect(() => {
    let filteredData = filterByEngineType(productList);
    filteredData = filterByModelClass(filteredData);
    filteredData = filterByBusinessType(filteredData);
    filteredData = filterBySegmentType(filteredData);
    filteredData = filterByBodyType(filteredData);
    setFilteredList(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkedEngineCategory,
    checkedModelSubCategory,
    selectedBusinessType,
    selectedSegmentType,
    selectedBodyType,
  ]);

  //
  // Compare Panel
  //
  const [, setModel1] = useLocalStorage(
    CV_PRODUCT_MODEL_1_LOCAL_STORAGE_KEY,
    ''
  );
  const [, setModel2] = useLocalStorage(
    CV_PRODUCT_MODEL_2_LOCAL_STORAGE_KEY,
    ''
  );
  const [, setModel3] = useLocalStorage(
    CV_PRODUCT_MODEL_3_LOCAL_STORAGE_KEY,
    ''
  );
  const [selectedCompare, setSelectedCompare] = useLocalStorage(
    CV_PRODUCT_SELECTED_COMPARE_LOCAL_STORAGE_KEY,
    { selections: [] }
  );
  const [showComparePanel, setShowComparePanel] = useState<boolean>(false);
  const [showCompareItemsMobile, setShowCompareItemsMobile] =
    useState<boolean>(false);
  const [disabledCompareButtonItem, setDisabledCompareButtonItem] =
    useState<boolean>(false);
  const [enableCompareButton, setEnableCompareButton] =
    useState<boolean>(false);

  const _onClickClearCompare = () => {
    setSelectedCompare({ selections: [] });
    setModel1('');
    setModel2('');
    setModel3('');
  };

  const [compareResultData, setCompareResultData] = useState<string[]>([]);
  useEffect(() => {
    const modelCompareResultArray = [];
    selectedCompare.selections.filter((item) => {
      modelClassList.forEach((o) => {
        if (item === o.uid) {
          modelCompareResultArray.push(o.title);
        }
      });
    });
    setCompareResultData(modelCompareResultArray);
  }, [modelClassList, selectedCompare, selectedCompare.selections]);

  const _onLink = () => {
    const modelCompareResultArray = [];
    selectedCompare.selections.filter((item) => {
      modelClassList.forEach((o) => {
        if (item === o.uid) {
          modelCompareResultArray.push(o.title);
          trackProductListCompare({
            modelGroup: null,
            modelClass: o.title,
            modelSubCategory: null,
            businessType: null,
            segmentType: null,
            bodyType: null,
          });
        }
      });
    });

    checkedModelSubCategory.selections.filter((item) => {
      modelSubCategoryList.forEach((o) => {
        if (item === o.uid) {
          trackProductListCompare({
            modelGroup: null,
            modelClass: null,
            modelSubCategory: o.label ? o.label : null,
            businessType: null,
            segmentType: null,
            bodyType: null,
          });
        }
      });
    });

    let businessTypeCompareResult;
    businessTypeList.forEach((o) => {
      if (selectedBusinessType === o.uid) {
        businessTypeCompareResult = o.label;
      }
    });

    let segmentTypeCompareResult;
    segmentTypeList.forEach((o) => {
      if (selectedSegmentType === o.uid) {
        segmentTypeCompareResult = o.label;
      }
    });

    let bodyTypeCompareResult;
    bodyTypeList.forEach((o) => {
      if (selectedBodyType === o.uid) {
        bodyTypeCompareResult = o.label;
      }
    });

    trackProductListCompare({
      modelGroup: modelCompareResultArray,
      modelClass: null,
      modelSubCategory: null,
      businessType: businessTypeCompareResult
        ? businessTypeCompareResult
        : null,
      segmentType: segmentTypeCompareResult ? segmentTypeCompareResult : null,
      bodyType: bodyTypeCompareResult ? bodyTypeCompareResult : null,
    });

    if (selectedCompare.selections.length >= 2) {
      const searchParams = new URLSearchParams(
        selectedCompare.selections.map((o) => ['model', o])
      );
      router.push(`/product/compare?${searchParams.toString()}`);
    }
  };

  const getCompareTitle = (uid) => {
    let showCompareTitle;
    if (productList) {
      productList
        .filter((item) => item.uid === uid)
        .map((o) => {
          if (o.title) {
            showCompareTitle = o.title;
          }
        });
    }
    return showCompareTitle;
  };

  const handleCompareChange = (key: string) => {
    const sel = selectedCompare.selections;
    const find = sel.indexOf(key);

    if (find > -1) {
      sel.splice(find, 1);
    } else {
      sel.push(key);
      trackProductListFilter({
        modelClass: getCompareTitle(key),
        modelSubCategory: '',
        businessType: '',
        segmentType: '',
        bodyType: '',
      });
    }

    setSelectedCompare({
      selections: sel,
    });

    if (selectedCompare.selections[0]) {
      setModel1(selectedCompare.selections[0]);
    } else {
      setModel1('');
    }

    if (selectedCompare.selections[1]) {
      setModel2(selectedCompare.selections[1]);
    } else {
      setModel2('');
    }

    if (selectedCompare.selections[2]) {
      setModel3(selectedCompare.selections[2]);
    } else {
      setModel3('');
    }

    setShowCompareItemsMobile(true);
  };

  useEffect(() => {
    if (!windowSize.width) return;
    if (!selectedCompare) return;

    if (selectedCompare.selections.length > 0) {
      setShowComparePanel(true);
    } else {
      setShowComparePanel(false);
    }

    if (selectedCompare.selections.length >= 2) {
      setEnableCompareButton(true);
    } else {
      setEnableCompareButton(false);
    }

    if (windowSize.width >= 1024) {
      if (selectedCompare.selections.length >= 3) {
        setDisabledCompareButtonItem(true);
      } else {
        setDisabledCompareButtonItem(false);
      }
    } else {
      if (selectedCompare.selections.length >= 2) {
        setDisabledCompareButtonItem(true);
      } else {
        setDisabledCompareButtonItem(false);
      }
    }
  }, [selectedCompare, setSelectedCompare, windowSize.width]);

  useEffect(() => {
    const handleScroll = () => {
      setShowCompareItemsMobile(false);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isInitialRender = useCheckInitialRender();

  return (
    <>
      <Section overflow="visible" test="section_product_listing">
        <HeadingLevel1
          title={props.title}
          description={props.description}
          buttons={props.buttons}
        />
        <Box
          position="sticky"
          top="sticky-top"
          left="0"
          width="full"
          backgroundColor="gray800"
          zIndex="2"
          cursor="pointer"
          display={{ '@lg': 'none' }}
          onClick={() => setOpenFilterPanel(!openFilterPanel)}
          test={{
            dataTest: 'search_filter_bar',
          }}
        >
          <Container>
            <Box paddingY="2">
              <Flex alignItems="center" columnGap="3">
                <ButtonIcon
                  icon={props?.filterPanelIcon}
                  size="md"
                  variant="button-icon-color-red"
                />
                <Text
                  as="h2"
                  size="text-body-1"
                  font="bold"
                  variant="text-white"
                >
                  {props.filterPanelTitle}
                </Text>
              </Flex>
            </Box>
          </Container>
        </Box>
        <ProductList>
          <ProductListFilterPanelOverlay
            position="fixed"
            top="0"
            left="0"
            width="w-screen"
            height="viewport-height"
            backgroundColor="backdrop"
            cursor="pointer"
            zIndex="overlay"
            pointerEvents="none"
            opacity="0"
            className={openFilterPanel ? 'is-active' : ''}
            onClick={() => setOpenFilterPanel(!openFilterPanel)}
          />
          <ProductListFilterPanel
            className={openFilterPanel ? 'is-active' : ''}
            data-test="panel_filter"
          >
            <ProductListFilterPanelOuter direction="column">
              <Box
                backgroundColor="gray800"
                paddingY="2"
                paddingLeft="6"
                paddingRight="3"
              >
                <Flex
                  alignItems="center"
                  justifyContent="between"
                  columnGap="3"
                >
                  <Flex alignItems="center" columnGap="3">
                    {/* eslint-disable-next-line react/jsx-max-depth */}
                    <Icon icon={props?.filterPanelIcon} color="icon-white" />
                    {/* eslint-disable-next-line react/jsx-max-depth */}
                    <Text
                      as="h2"
                      font="bold"
                      size="text-body-1"
                      variant="text-white"
                      css={{ bottom: -1 }}
                    >
                      {props.filterPanelTitle}
                    </Text>
                  </Flex>
                  {/* eslint-disable-next-line react/jsx-max-depth */}
                  <ButtonIcon
                    icon="close"
                    variant="button-icon-color-white"
                    size="md"
                    isTransparent
                    css={{ '@lg': { visibility: 'hidden' } }}
                    onClick={() => setOpenFilterPanel(!openFilterPanel)}
                    test={{
                      dataTest: 'button_exit_search_filter',
                    }}
                  />
                </Flex>
              </Box>
              <ProductListFilterPanelBody backgroundColor="white">
                <ProductListFilterPanelBodyScroll>
                  <Box padding="6">
                    <Grid rowGap="6">
                      <Grid rowGap="2">
                        <Flex
                          alignItems="center"
                          justifyContent="between"
                          columnGap="3"
                        >
                          {/* eslint-disable-next-line react/jsx-max-depth */}
                          <Text
                            as="h3"
                            font="bold"
                            size="text-body-2"
                            variant="text-gray-darkest"
                          >
                            {props.modelSubCategoryLabel}
                          </Text>
                          <ProductListResetFilter
                            type="reset"
                            onClick={_onClickClearFilter}
                            data-test="clear_filter"
                          >
                            {/* eslint-disable-next-line react/jsx-max-depth */}
                            <Text
                              size="text-body-2"
                              font="bold"
                              variant="text-blue"
                            >
                              {props.filterResultClearButtonLabel}
                            </Text>
                          </ProductListResetFilter>
                        </Flex>
                        {/* eslint-disable-next-line react/jsx-max-depth */}
                        <CustomCheckboxGroup>
                          {modelSubCategorySort.map((o, i) => (
                            <CustomCheckbox
                              key={i}
                              name={o.name}
                              value={o.uid}
                              label={o.label}
                              handleOnChange={(event) =>
                                handleCheckboxChange(event, o.uid, o.label)
                              }
                              selected={checkedModelSubCategory.selections.includes(
                                o.uid
                              )}
                              tracking={{
                                dataTrack: 'product-list-model-category',
                                dataTrackValue: o.label,
                              }}
                              test={{
                                dataTest: 'button_filter_' + o.name,
                              }}
                            />
                          ))}
                        </CustomCheckboxGroup>
                      </Grid>
                      {/* eslint-disable-next-line react/jsx-max-depth */}
                      <Divider />
                      <Select
                        name="business_type"
                        label={props.businessTypeLabel}
                        value={selectedBusinessType}
                        onChange={handleBusinessTypeChange}
                        tracking={{
                          dataTrack: 'product-list-business-type',
                        }}
                      >
                        {/* eslint-disable-next-line react/jsx-max-depth */}
                        <option value="">
                          {props.businessTypePlaceholder}
                        </option>
                        {businessTypeSort.map((o, i) => (
                          <option key={i} value={o.uid}>
                            {o.label}
                          </option>
                        ))}
                      </Select>
                      {/* eslint-disable-next-line react/jsx-max-depth */}
                      <Divider />
                      <Select
                        name="segment_type"
                        label={props.segmentTypeLabel}
                        value={selectedSegmentType}
                        onChange={handleSegmentTypeChange}
                        tracking={{
                          dataTrack: 'product-list-segment-type',
                        }}
                      >
                        {/* eslint-disable-next-line react/jsx-max-depth */}
                        <option value="">{props.segmentTypePlaceholder}</option>
                        {segmentTypeSort.map((o, i) => (
                          <option key={i} value={o.uid}>
                            {o.label}
                          </option>
                        ))}
                      </Select>
                      {/* eslint-disable-next-line react/jsx-max-depth */}
                      <Divider />
                      <Select
                        name="body_type"
                        label={props.bodyTypeLabel}
                        value={selectedBodyType}
                        onChange={handleBodyTypeChange}
                        tracking={{
                          dataTrack: 'product-list-body-type',
                        }}
                      >
                        {/* eslint-disable-next-line react/jsx-max-depth */}
                        <option value="">{props.bodyTypePlaceholder}</option>
                        {bodyTypeSort.map((o, i) => (
                          <option key={i} value={o.uid}>
                            {o.label}
                          </option>
                        ))}
                      </Select>
                    </Grid>
                  </Box>
                </ProductListFilterPanelBodyScroll>
              </ProductListFilterPanelBody>
            </ProductListFilterPanelOuter>
          </ProductListFilterPanel>
          <ProductListContent
            paddingBottom="10"
            backgroundColor="gray300"
            test={{
              dataTest: 'panel_filterResult',
            }}
          >
            <Container
              css={{
                '@lg': {
                  paddingLeft: '$space-10',
                  paddingRight: '$space-10',
                  height: '100%',
                },
              }}
            >
              <Flex direction="column" css={{ height: '100%' }}>
                <ProductListingTotal total={filteredList.length} />
                {!!filteredList && filteredList.length === 0 && <NotFound />}
                {!!filteredList && filteredList.length > 0 && (
                  <ProductListContentItems gap="6">
                    {filteredList.map((o, i) => (
                      <ProductItem
                        key={i}
                        {...o}
                        businessTypeList={businessTypeList}
                        segmentTypeList={segmentTypeList}
                        bodyTypeList={bodyTypeList}
                        selectedCompare={selectedCompare}
                        handleCompareChange={handleCompareChange}
                        disabledCompareButtonItem={disabledCompareButtonItem}
                        itemIndex={i}
                      />
                    ))}
                  </ProductListContentItems>
                )}
                {!!props.remarkLabel && filteredList.length > 0 && (
                  <Box marginTop="6">
                    <Text
                      as="div"
                      size="text-caption"
                      wrap
                      dangerouslySetInnerHTML={{ __html: props.remarkLabel }}
                      data-test="remark"
                    />
                  </Box>
                )}
              </Flex>
            </Container>
          </ProductListContent>
        </ProductList>
      </Section>
      {!isInitialRender && selectedCompare.selections.length > 0 && (
        <ProductComparePanel
          productList={productList}
          selectedCompare={selectedCompare}
          compareResultData={compareResultData}
          showComparePanel={showComparePanel}
          showCompareItemsMobile={showCompareItemsMobile}
          enableCompareButton={enableCompareButton}
          handleCompareChange={handleCompareChange}
          setShowCompareItemsMobile={setShowCompareItemsMobile}
          onClear={_onClickClearCompare}
          onCompare={_onLink}
        />
      )}
    </>
  );
};
View.displayName = 'SectionProductListing';
export default View;
