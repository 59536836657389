import React, { useEffect, useRef, useState } from 'react';
import { Keyboard, Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { useMediaQuery } from '@/components/hooks/useMediaQuery';
import { useModal } from '@/components/hooks/useModal';
import { Text } from '@/components/shared/element/text';
import { Container } from '@/components/shared/layout/container';
import { generateBannerImageSources } from '@/components/shared/media/image/generate-sources';
import { Video } from '@/components/shared/media/video';
import { BannerContentWithLogo } from '@/components/shared/my24/banner-content-with-logo';
import { PaginationBar } from '@/components/shared/navigation/pagination-bar';
import { playVideoWithPromiseHandle } from '@/components/shared/utility/video';
import { config, styled } from '@/stitches.config';
import { IHeadline, IItem, IMy24Tracking } from '@/types/shared';

const HeroSection = styled('section', {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  bc: '$black',
  '@maxlg': {
    '@media (orientation: portrait)': {
      height: 'calc(100vh - var(--header-height))',
      aspectRatio: '2 / 3',
      '@supports (height: 100svh)': {
        height: 'calc(100svh - var(--header-height))',
      },
    },
    '@media (orientation: landscape)': {
      aspectRatio: '3 / 4',
    },
  },
  '@lg': {
    minHeight: 480,
    height: 'calc(100vh - var(--header-height))',
  },
  '& .swiper': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    transform: 'translateZ(0)',
  },
  '& .swiper-slide': {
    transform: 'translateZ(0)',
    overflow: 'hidden',
  },
  '& .swiper-slide-duplicate': {
    opacity: 0,
    '&[data-anim="fade"]': {
      opacity: 1,
    },
  },
});

export interface IMY24HeroBanner {
  headline: IHeadline;
  items?: IItem[];
  isCloseSplash?: boolean;
  dataTrack?: IMy24Tracking;
}

const LCVHomeBanner: React.FC<IMY24HeroBanner> = ({
  headline: { title },
  items,
  isCloseSplash,
  dataTrack = 'lcv-home',
}) => {
  const [swiperMedia, setSwiperMedia] = useState<SwiperClass | null>(null);
  const [startAnimatePagination, setStartAnimatePagination] =
    useState<boolean>(false);
  const [pauseVideo, setPauseVideo] = useState<boolean>(false);
  const swiperRef = useRef(null);
  const isPortrait = useMediaQuery(config.media.portrait);

  const onResetActiveVideo = () => {
    if (!swiperMedia) return;

    const INDEX_SLIDE = swiperMedia.activeIndex;
    swiperMedia.slides &&
      swiperMedia.slides.forEach((o, i) => {
        if (i === INDEX_SLIDE) {
          const VIDEO = o.querySelector('video');

          if (VIDEO) {
            VIDEO.pause();
            VIDEO.currentTime = 0;
            setStartAnimatePagination(false);
          }
        }
      });
  };

  const onPlayMedia = () => {
    if (!swiperMedia) return;
    if (pauseVideo) return;
    if (!isCloseSplash) return;
    if (!swiperMedia.pagination) return;

    const INDEX_SLIDE = swiperMedia?.activeIndex;
    const ACTIVE_SLIDE = swiperMedia?.slides?.[INDEX_SLIDE];
    const ACTIVE_VIDEO = ACTIVE_SLIDE?.querySelector('video');

    // reset all video except active
    if (swiperMedia.slides) {
      swiperMedia.slides.forEach((o, i) => {
        if (i !== INDEX_SLIDE) {
          const VIDEO = o.querySelector('video');

          if (VIDEO) {
            VIDEO.pause();
          }
        }
      });
    }
    if (ACTIVE_VIDEO) {
      // NOTE: video
      const BULLETS = swiperMedia.pagination?.bullets;

      const playVideo = () => {
        if (BULLETS) {
          let DURATION = 8;
          if (ACTIVE_VIDEO) {
            DURATION = ACTIVE_VIDEO.duration;
          }
          BULLETS?.[swiperMedia.realIndex]?.style.setProperty(
            '--duration',
            `${DURATION}s`
          );
        }

        setStartAnimatePagination(false);
        playVideoWithPromiseHandle(ACTIVE_VIDEO);
        setStartAnimatePagination(true);
      };
      if (ACTIVE_VIDEO.readyState >= 2) {
        playVideo();
      } else {
        ACTIVE_VIDEO.addEventListener(
          'loadedmetadata',
          () => {
            playVideo();
          },
          { once: true }
        );
      }
    } else {
      // NOTE: image
      setStartAnimatePagination(true);
    }
  };

  const onPauseVideo = () => {
    if (!swiperMedia) return;

    setPauseVideo(true);
    swiperMedia.slides &&
      swiperMedia.slides.forEach((o) => {
        const VIDEO = o.querySelector('video');
        if (VIDEO) {
          VIDEO.pause();
        }
      });
  };

  const onPlayVideo = () => {
    if (!swiperMedia) return;

    setPauseVideo(false);
    const INDEX_SLIDE = swiperMedia?.activeIndex;
    const ACTIVE_SLIDE = swiperMedia?.slides?.[INDEX_SLIDE];
    const ACTIVE_VIDEO = ACTIVE_SLIDE.querySelector('video');
    playVideoWithPromiseHandle(ACTIVE_VIDEO);
  };

  // NOTE: init
  useEffect(() => {
    if (!swiperMedia || swiperMedia.destroyed) return;
    swiperMedia.slides?.forEach((o) => {
      const DUP_SLIDE = o.classList.contains('swiper-slide-duplicate');

      if (DUP_SLIDE) {
        o.setAttribute('data-anim', 'fade');
      }
    });
  }, [swiperMedia]);

  // NOTE: progress bar animation end for image
  const handleAnimationEnd = (e) => {
    if (pauseVideo) return;
    if (e.elapsedTime === 0) return;

    const currentItem = items[swiperMedia.realIndex];
    if (currentItem.video) return;

    onSlideNext();
  };

  // NOTE: handle video event
  const onVideoEnded = () => {
    if (pauseVideo) return;
    onSlideNext();
  };

  useEffect(() => {
    if (!swiperMedia) return;
    if (!isCloseSplash) return;
    if (!swiperMedia.pagination) return;
    // NOTE: progress bar animation end for image
    const BULLETS = swiperMedia.pagination?.bullets;
    BULLETS.forEach((o) => {
      o.addEventListener('animationend', handleAnimationEnd);
    });

    swiperMedia.slides.forEach((o) => {
      const VIDEO = o.querySelector('video');
      if (VIDEO) {
        VIDEO.addEventListener('ended', onVideoEnded);
      }
    });

    onPlayMedia();

    return () => {
      BULLETS.forEach((o) => {
        o.removeEventListener('animationend', handleAnimationEnd);
      });

      swiperMedia.slides &&
        swiperMedia.slides.forEach((o) => {
          const VIDEO = o.querySelector('video');
          if (VIDEO) {
            VIDEO.removeEventListener('ended', onVideoEnded);
          }
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperMedia, isCloseSplash]);

  useEffect(() => {
    if (!swiperMedia) return;

    const REAL_INDEX = swiperMedia.realIndex;
    const INDEX_SLIDE = swiperMedia.activeIndex;
    const ACTIVE_SLIDE = swiperMedia.slides?.[INDEX_SLIDE];
    const ACTIVE_VIDEO = ACTIVE_SLIDE?.querySelector('video');

    if (!ACTIVE_VIDEO) return;

    // NOTE: reset slide
    swiperMedia.loopedSlides
      ? swiperMedia.slideToLoop(REAL_INDEX + 1, 0)
      : swiperMedia.slideTo(REAL_INDEX + 1, 0);

    swiperMedia.loopedSlides
      ? swiperMedia.slideToLoop(REAL_INDEX, 0)
      : swiperMedia.slideTo(REAL_INDEX, 0);
  }, [isPortrait, swiperMedia]);

  const onSlideNext = () => {
    if (!swiperMedia) return;
    if (pauseVideo) return;

    swiperMedia.slideNext();
  };

  const onOpenModal = (id: string) => {
    if (swiperMedia.animating) return;
    modal.open({ youtubeID: id });
    onPauseVideo();
    const ALL_SWIPER = document.querySelectorAll('.swiper');
    ALL_SWIPER.forEach((o: any) => {
      const SWIPER = o.swiper;
      SWIPER?.keyboard?.disable();
    });
  };

  const onCloseModal = () => {
    onPlayVideo();
    const ALL_SWIPER = document.querySelectorAll('.swiper');
    ALL_SWIPER.forEach((o: any) => {
      const SWIPER = o.swiper;
      SWIPER?.keyboard?.enable();
    });
  };

  const { api: modal, component: modalComponent } = useModal({
    type: 'video',
    onClose: onCloseModal,
  });

  return (
    <>
      {items?.length > 0 && (
        <HeroSection data-test="section_banner" className="h-screen-w-header">
          {!!title && (
            <Text as="h1" size="h1" sr-only>
              {title}
            </Text>
          )}
          <Swiper
            ref={swiperRef}
            modules={[Pagination, Keyboard]}
            rewind
            keyboard
            speed={1000}
            slidesPerView={1}
            threshold={10}
            pagination={{
              el: '.swiper-pagination-home-banner',
              clickable: true,
              renderBullet: function (index, className) {
                return `<div class="${className}" data-test="pagination_${
                  index + 1
                }"><span>${index + 1}</span>
                </div>`;
              },
            }}
            onSwiper={setSwiperMedia}
            onSlideChangeTransitionStart={onResetActiveVideo}
            onSlideChangeTransitionEnd={onPlayMedia}
            data-test={`banner_items`}
          >
            {items?.slice(0, 9).map((item, i) => (
              <SwiperSlide key={i} data-test={`banner_item_${i + 1}`}>
                <Video
                  imagePriority={i === 0 && isCloseSplash}
                  imageRootMargin={isCloseSplash ? '0px' : '-1px'}
                  imageSrc={item.image?.src}
                  imageAlt={item.image?.alt}
                  imageWidth={600}
                  imageHeight={800}
                  imageSources={generateBannerImageSources({
                    mobileSrc: item.image?.src,
                    mobileOptions: {
                      quality: item.video?.src ? 60 : undefined,
                    },
                    desktopSrc: item.imageDesktop?.src,
                    desktopOptions: {
                      quality: item.videoDesktop?.src ? 60 : undefined,
                    },
                  })}
                  autoplay={false}
                  video={item?.video?.src}
                  videoDesktop={item?.videoDesktop?.src}
                />
                <BannerContentWithLogo
                  itemId={i}
                  item={item}
                  dataTest="banner"
                  dataTrack={dataTrack}
                  onClick={() => onOpenModal(item?.youtube?.url)}
                  isCloseSplash={isCloseSplash}
                  containerElement={swiperRef.current}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {items?.length > 1 && (
            <PaginationBar
              animateBanner
              startAnimateBanner={startAnimatePagination}
              pauseActiveBar={pauseVideo}
              justify={{ '@lg': 'start' }}
              css={{
                position: 'absolute',
                left: 0,
                bottom: '$space-8',
                '@xs': {
                  bottom: '$space-10',
                },
                '@sm': {
                  bottom: '$space-14',
                },
                '@md': {
                  bottom: '$space-16',
                },
                '@lg': {
                  bottom: '$space-14',
                },
              }}
            >
              <Container>
                <div
                  className="swiper-pagination swiper-pagination-home-banner"
                  data-test="paginations"
                />
              </Container>
            </PaginationBar>
          )}
        </HeroSection>
      )}
      {modalComponent}
    </>
  );
};
LCVHomeBanner.displayName = 'SectionMY24HomeHeroBanner';
export default LCVHomeBanner;
