import type { CSS } from '@stitches/react';

import { keyframes, styled } from '@/stitches.config';

export const animateProgressBar = keyframes({
  '0%': { transform: 'scaleX(0)' },
  '100%': { transform: 'scaleX(1)' },
});

const StyledPaginationBar = styled('div', {
  position: 'relative',
  width: '100%',
  pe: 'none',
  zIndex: 1,
  '& .swiper-pagination, & .pagination': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '$space-2',
    fontSize: 0,
    height: '$size-1',
    '@md': {
      columnGap: '$space-3',
    },
  },
  '& .swiper-pagination-bullet, & .pagination-bullet': {
    position: 'relative',
    flexGrow: 1,
    flexBasis: '$size-6',
    maxWidth: '$size-6',
    height: '100%',
    cursor: 'pointer',
    pe: 'all',
    '@md': {
      flexBasis: '$size-10',
      maxWidth: '$size-10',
    },
    '@lg': {
      width: '$size-10',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 'calc(50% - $space-6)',
      left: 0,
      height: '$size-12',
      width: '100%',
      bc: 'rgba(0, 0, 0, 0)',
    },
    '& span': {
      position: 'relative',
      display: 'block',
      height: '100%',
      width: '100%',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
      },
      '&::after': {
        opacity: 0,
        transition: 'opacity 1s cubic-bezier(0.5, 0, 0, 0.75) 0s',
      },
    },
    '&.swiper-pagination-bullet-active, &.pagination-bullet-active': {
      '& span': {
        '&::after': {
          opacity: 1,
        },
      },
    },
  },
  variants: {
    justify: {
      start: {
        '& .swiper-pagination, & .pagination': {
          justifyContent: 'flex-start',
        },
      },
      center: {
        '& .swiper-pagination, & .pagination': {
          justifyContent: 'center',
        },
      },
      end: {
        '& .swiper-pagination, & .pagination': {
          justifyContent: 'flex-end',
        },
      },
    },
    variant: {
      white: {
        '& .swiper-pagination-bullet, & .pagination-bullet': {
          '& span': {
            '&::before': {
              bc: '$white',
              opacity: 0.3,
            },
            '&::after': {
              bc: '$white',
            },
          },
        },
      },
      red: {
        '& .swiper-pagination-bullet, & .pagination-bullet': {
          '& span': {
            '&::before': {
              bc: '$gray400',
            },
            '&::after': {
              bc: '$primary',
            },
          },
        },
      },
      black: {
        '& .swiper-pagination-bullet, & .pagination-bullet': {
          '& span': {
            '&::before': {
              bc: '$gray400',
            },
            '&::after': {
              bc: '$gray900',
            },
          },
        },
      },
      lime: {
        '& .swiper-pagination-bullet, & .pagination-bullet': {
          '& span': {
            '&::before': {
              bc: '$gray900',
            },
            '&::after': {
              bc: '$lime',
            },
          },
        },
      },
    },
    animate: {
      true: {
        '--duration': '8s',
        '& .swiper-pagination-bullet': {
          '& span': {
            '&::after': {
              opacity: 1,
              transform: 'scaleX(0)',
              transformOrigin: 'left',
            },
          },
          '&.swiper-pagination-bullet-active': {
            '& span': {
              '&::after': {
                animationName: `${animateProgressBar}`,
                animationDuration: 'var(--duration)',
                animationTimingFunction: 'linear',
                animationIterationCount: 1,
                animationDirection: 'alternate',
                animationFillMode: 'none',
              },
            },
          },
        },
      },
    },
    stopAnimate: {
      true: {
        '& .swiper-pagination-bullet': {
          '&.swiper-pagination-bullet-active': {
            '& span': {
              '&::after': {
                animationPlayState: 'paused',
              },
            },
          },
        },
      },
    },
    animateBanner: {
      true: {
        '--duration': '8s',
        '& .swiper-pagination-bullet': {
          '& span': {
            '&::after': {
              opacity: 1,
              transform: 'scaleX(0)',
              transformOrigin: 'left',
            },
          },
        },
      },
    },
    startAnimateBanner: {
      true: {
        '& .swiper-pagination-bullet': {
          '&.swiper-pagination-bullet-active': {
            '& span': {
              '&::after': {
                animationName: `${animateProgressBar}`,
                animationDuration: 'var(--duration)',
                animationTimingFunction: 'linear',
                animationFillMode: 'forwards',
                animationPlayState: 'running',
              },
            },
          },
        },
      },
    },
    pauseActiveBar: {
      true: {
        '& .swiper-pagination-bullet': {
          '&.swiper-pagination-bullet-active': {
            '& span': {
              '&::after': {
                animationPlayState: 'paused !important',
              },
            },
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'white',
  },
});

export interface PaginationBarProps
  extends React.ComponentProps<typeof StyledPaginationBar> {
  children?: React.ReactNode;
  css?: CSS;
  className?: string;
}

export const PaginationBar: React.FC<PaginationBarProps> = ({
  justify,
  variant,
  animate,
  stopAnimate,
  animateBanner,
  startAnimateBanner,
  pauseActiveBar,
  css,
  className,
  children,
}: PaginationBarProps) => {
  return (
    <StyledPaginationBar
      justify={justify}
      variant={variant}
      animate={animate}
      stopAnimate={stopAnimate}
      animateBanner={animateBanner}
      startAnimateBanner={startAnimateBanner}
      pauseActiveBar={pauseActiveBar}
      css={css}
      className={className}
    >
      {children}
    </StyledPaginationBar>
  );
};
PaginationBar.displayName = 'PaginationBar';
